import React from 'react';
import { Tabs, TabList, Tab, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface PyroTabsComponentProps {
  isTestMode: boolean;
  setIsTestMode: (value: boolean) => void;
}

const PyroTabsComponent: React.FC<PyroTabsComponentProps> = ({
  isTestMode,
  setIsTestMode,
}) => {
  const { t } = useTranslation();

  // Map boolean to string for the value prop
  const value = isTestMode ? 'test' : 'public';

  const handleChange = (newValue: string | number | null) => {
    // console.log(newValue);
    if (newValue === 'test') {
      setIsTestMode(true);
    } else if (newValue === 'public') {
      setIsTestMode(false);
    }
  };

  return (
    <Tabs
      aria-label="test-mode"
      value={value}
      onChange={(e, value) => handleChange(value)}
      sx={{ width: '100%', position: 'sticky', top: 0, zIndex: 10 }}
    >
      <TabList variant="plain" sx={{ width: '100%' }}>
        <Tab
          color={isTestMode ? 'warning' : 'primary'}
          variant={isTestMode ? 'soft' : 'outlined'}
          sx={{ width: '100%' }}
          value="test"
        >
          <span className="material-symbols-outlined">build</span>
          {t('testMode')}
        </Tab>
        <Tab
          color={isTestMode ? 'primary' : 'primary'}
          variant={isTestMode ? 'outlined' : 'soft'}
          sx={{ width: '100%' }}
          value="public"
        >
          <span className="material-symbols-outlined">groups</span>
          {t('publicMode')}
        </Tab>
      </TabList>
    </Tabs>
  );
};

export default PyroTabsComponent;
