// src/services/websocketService.ts

import io, { Socket } from 'socket.io-client';

class WebSocketService {
  private static instance: WebSocketService;
  private socket: Socket | null = null;

  private constructor() {}

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  public connect(BASE_URL: string): Socket {
    if (!this.socket) {
      const wsBaseUrl = BASE_URL.replace(/^http/, 'ws');
      this.socket = io(wsBaseUrl, { path: '/socket.io' });

      // Add logging
      // this.socket.on('connect', () => {
      //   console.log('WebSocket connected with id:', this.socket?.id);
      // });

      // this.socket.on('disconnect', () => {
      //   console.log('WebSocket disconnected.');
      // });

      // this.socket.on('connect_error', error => {
      //   console.error('WebSocket connect error:', error);
      // });
    }
    return this.socket;
  }

  public disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  public getSocket(): Socket | null {
    return this.socket;
  }
}

export default WebSocketService;
