import React from 'react';
import { useTranslation } from 'react-i18next'; // Text

const NotFoundPage = () => {
  const { t } = useTranslation();

  return <div>{t('404Page')}</div>;
};

export default NotFoundPage;
