import React from 'react';

import { Table, IconButton, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { UserInfo } from '../../../types/userTypes';

interface UsersTableProps {
  users: UserInfo[];
  // onEditUser?: (id: string) => void;
  onDeleteUser: (id: string) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ users, onDeleteUser }) => {
  //onEditUser
  const { t } = useTranslation();
  return (
    <Table aria-label="users table" variant="outlined" color="primary" hoverRow>
      <thead>
        <tr>
          <th align="left">Email</th>
          <th align="left">{t('business')}</th>
          <th align="left">{t('role')}</th>
          <th align="left">{t('verified')}</th>
          <th align="left">{t('action')}</th>
        </tr>
      </thead>
      <Box component="tbody" sx={{ borderRadius: '2em' }}>
        {users.map(user => (
          <Box
            component="tr"
            key={user.id}
            sx={{
              '&:last-child td, &:last-child th': {},
              '&:hover': {
                '.action-icons': {
                  // Class for action icons
                  visibility: 'visible', // Only show icons when row is hovered
                },
              },
              '.action-icons': {
                visibility: 'hidden', // Initially hide icons
              },
            }}
          >
            <td align="left">{user.email}</td>
            <td align="left">{user.businessId}</td>
            <td align="left">{user.role}</td>
            <td align="left">{user.verified}</td>
            <td align="right">
              {/* <IconButton onClick={() => onEditUser(user.id)} className="action-icons">
                  <EditIcon />
                </IconButton> */}
              <IconButton onClick={() => onDeleteUser(user.id!)} className="action-icons">
                <span className="material-symbols-outlined">delete</span>
              </IconButton>
            </td>
          </Box>
        ))}
      </Box>
    </Table>
  );
};

export default UsersTable;
