// src/hooks/useLoginForm.ts

import { useState, useContext } from 'react';
import { API } from '../../../utils/API';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

interface LoginFormState {
  email: string;
  password: string;
}

const useLoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    setIsAuthenticated,
    setUserEmail,
    setUserRole,
    setUserBusinessId,
    setUserBusinessName,
  } = useContext(AuthContext);

  const [formState, setFormState] = useState<LoginFormState>({
    email: '',
    password: '',
  });

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = (field: keyof LoginFormState, value: string) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, password } = formState;

    try {
      setIsLoading(true);
      const user = await API.post(
        `/api/v1/auth/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      );
      // Store the received token or user data
      localStorage.setItem('authToken', user.data.token);
      // Update the authentication context
      setIsAuthenticated(true);
      setUserEmail(user.data.email);
      setUserRole(user.data.role);
      setUserBusinessId(user.data.businessId);
      setUserBusinessName(user.data.businessName);
      // Navigate to the appropriate page
      navigate('/admin/events/');
    } catch (error: any) {
      console.error('Login failed:', error.response?.data || error);
      setErrorMessage(t('loginError'));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    formState,
    handleInputChange,
    handleLogin,
    errorMessage,
    isLoading,
  };
};

export default useLoginForm;
