import React from 'react';

import { useTranslation } from 'react-i18next'; // Text

import BusinessesTable from './BusinessesTable';
import SideBarComponent from '../../components/SideBarComponent';

import { CssBaseline, Box } from '@mui/joy';
import useBusinessList from '../../hooks/useBusinessList';

interface AdminBusinessesPageProps {}

const AdminBusinessesPage: React.FC<AdminBusinessesPageProps> = ({}) => {
  const { t } = useTranslation();
  const { businesses, loading, error, handleDeleteBusiness } = useBusinessList();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SideBarComponent settings={false} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: '1em',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
          <h2>{t('businesses')}</h2>
        </Box>
        <Box className="admin-table">
          {businesses.length > 0 ? (
            <BusinessesTable
              businesses={businesses}
              onDeleteBusiness={handleDeleteBusiness}
            />
          ) : (
            <div>{t('noBusinesses')}</div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminBusinessesPage;
