// src/useNotification.js
import { useState } from 'react';
import {
  requestNotificationPermission,
  scheduleNotification,
} from '../utils/NotificationModel';

import { API } from '../utils/API';
import { urlBase64ToUint8Array } from '../utils/API';

interface NotificationOptions {
  body?: string;
  icon?: string;
  // additional properties can be added depending on what options are used
}

interface UseNotificationReturn {
  isNotificationGranted: boolean;
  requestPermission: () => Promise<void>;
  notify: (
    title: string,
    options: NotificationOptions,
    delay: number,
    url: string
  ) => void;
}

// Function to send subscription to the server
const sendSubscriptionToServer = async (
  subscription: PushSubscription,
  eventId: string
) => {
  await API.post('/api/v1/notifications', {
    subscription,
    eventId,
  });
};

const useNotification = (eventId: string): UseNotificationReturn => {
  const [isNotificationGranted, setIsNotificationGranted] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<PushSubscription | null>(null);

  const requestPermission = async () => {
    if (!eventId) {
      console.error('Event ID is not available.');
      return;
    }
    const permissionResult = await Notification.requestPermission();
    setIsNotificationGranted(permissionResult === 'granted');

    if (permissionResult === 'granted' && 'serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.ready;

      const existingSubscription = await registration.pushManager.getSubscription();
      if (existingSubscription) {
        setSubscription(existingSubscription);
        await sendSubscriptionToServer(existingSubscription, eventId);
      } else {
        const newSubscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            process.env.REACT_APP_VAPID_PUBLIC_KEY!
          ),
        });
        setSubscription(newSubscription);
        // Send the subscription to your server
        await sendSubscriptionToServer(newSubscription, eventId);
      }
    }
  };

  const notify = (
    title: string,
    options: NotificationOptions,
    delay: number,
    url: string
  ) => {
    if (isNotificationGranted) {
      scheduleNotification(title, options, delay, url);
    }
  };

  return { isNotificationGranted, requestPermission, notify };
};

export default useNotification;
