// src/hooks/useThemeSwitcher.js

import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';

interface UseThemeSwitcherReturn {
  mode: 'light' | 'dark';
  toggleTheme: () => void;
}

const useThemeSwitcher = (): UseThemeSwitcherReturn => {
  const { mode: materialMode, setMode: setMaterialMode } =
    useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();

  const mode = materialMode === 'system' ? 'light' : (materialMode ?? 'light');

  const toggleTheme = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMaterialMode(newMode);
    setJoyMode(newMode);
  };

  return { mode, toggleTheme };
};

export default useThemeSwitcher;
