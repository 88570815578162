// PublicPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Text

import { CssBaseline, Box, Typography } from '@mui/joy';
import { useTheme } from '@mui/joy';
import useBusinessInfo from '../../hooks/useBusinessInfo';
import useThemeSwitcher from '../../hooks/useThemeSwitcher';
import useEventsbyBusiness from './hooks/useEventsbyBusiness';

import EventCardStack from './components/EventCardStack';
interface BusinessEventsPageProps {}

const BusinessEventsPage: React.FC<BusinessEventsPageProps> = () => {
  const theme = useTheme();
  const { businessShortName } = useParams();
  const { t } = useTranslation();
  const { mode, toggleTheme } = useThemeSwitcher();

  // CHange text color depending on the mode
  const textColor =
    mode === 'dark' ? theme.palette.primary[50] : theme.palette.primary[500];

  const { businessInfo } = useBusinessInfo(`adminName/${businessShortName}`);
  const { events, isLoading, error } = useEventsbyBusiness(businessInfo?.shortName);

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          // height: '100vh',
          height: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'space-between',
          textAlign: 'left',
          maxWidth: '1024px',
          mx: 'auto',
          p: 2,
        }}
      >
        {/* Event Info */}
        {businessInfo && businessInfo.logoPath && (
          <Box
            component="img"
            src={businessInfo.logoPath}
            alt={`Image representing ${businessInfo ? businessInfo.name : 'business logo'}`}
            sx={{
              mx: 'auto',
              maxHeight: '64px',
              display: 'contain',
            }}
          />
        )}
        <Box sx={{ textAlign: 'left' }}>
          <Typography level="h1" sx={{ mt: 1, color: textColor }}>
            {businessInfo ? businessInfo.name : t('loading')}
          </Typography>
          <Typography level="body-sm" sx={{ color: textColor }}>
            {t('eventList')}
          </Typography>
        </Box>
        {/* Event Cards Stack */}
        {events && <EventCardStack events={events} />}

        <Box
          sx={{
            mt: 'auto',
            mb: '1em',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <img src={`/Logo_${mode}.svg`} alt="Orbiner Logo" style={{ height: '24px' }} />
          <Typography>
            {t('copyrightOrbiner', { date: new Date().getFullYear() })}
          </Typography>
          <Typography component="a" href="https://orbiner.com">
            orbiner.com
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default BusinessEventsPage;
