import { useState, useRef, useEffect } from 'react';
import { BASE_URL } from '../utils/API';
import io, { Socket } from 'socket.io-client';
const timesync = require('timesync');

const SYNC_BEFORE_MUSIC_STARTS = 5 * 60; //sec Launch a synchronization 5 min before music starts
const TIMEOUT_NETWORK_ISSUE = 60000; //ms : if not able to synchronize in a 1 min laps, then network issue

export interface UseNTPTimeReturn {
  isSynchronized: boolean;
  isNetworkError: boolean;
  ntpOffset: number | null;
  playbackDelta: number | null;
  deltaScheduledWServer: number | null;
}

const useNTPTime = (
  audioContext: AudioContext | null,
  audioContextState: AudioContextState | null,
  scheduledTime: Date | null,
  isEventLoading: boolean,
  isEventPassed: boolean
): UseNTPTimeReturn => {
  const [ntpOffset, setntpOffset] = useState<number | null>(null);
  const [playbackDelta, setPlaybackDelta] = useState<number | null>(null);
  const [deltaScheduledWServer, setDeltaScheduledWServer] = useState<number | null>(null);
  const [isSynchronized, setIsSynchronized] = useState<boolean>(false);
  const [isNetworkError, setIsNetworkError] = useState<boolean>(false);

  const [syncStatus, setSyncStatus] = useState<string | null>(null);
  const [syncAttempts, setSyncAttempts] = useState<number>(0);

  const socketRef = useRef<Socket | null>(null);
  const tsRef = useRef<any>(null);
  const syncIntervalRef = useRef<number | undefined>(undefined);

  // The page is now visible
  // Trigger a sync
  useEffect(() => {
    if (!isEventLoading && !isEventPassed) {
      const handleVisibilityChange = () => {
        // console.log('UseNTP : Page is active!')
        if (!document.hidden) {
          tsRef.current.sync();
        }
      };
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, []);

  // Setup WebSocket and TimeSync
  useEffect(() => {
    console.log(`Event is loading ${isEventLoading}`);
    // console.log(`Event is passed ${isEventPassed}`);
    if (!isEventLoading && !isEventPassed) {
      console.log('setting up websocket');
      const wsBaseUrl = BASE_URL.replace(/^http/, 'ws');
      const socket = io(wsBaseUrl, { path: '/socket.io' });
      socketRef.current = socket;

      const ts = timesync.create({
        server: socket,
        interval: null, // Disable automatic synchronization
      });
      tsRef.current = ts;

      ts.on('sync', function (state: string) {
        console.log('sync ' + state + '');
        setSyncStatus(state);
      });

      ts.on('change', function (newntpOffset: number) {
        // console.log(`ntpOffset changed : ${newntpOffset} ms`);
        setntpOffset(newntpOffset);
      });

      ts.send = function (socket: Socket, data: any, timeout: number) {
        console.log('send', data);
        return new Promise<void>(function (resolve, reject) {
          var timeoutFn = setTimeout(reject, timeout);
          socket.emit('timesync', data, function () {
            clearTimeout(timeoutFn);
            resolve();
          });
        });
      };

      socket.on('timesync', function (data) {
        ts.receive(null, data);
      });

      return () => {
        ts.destroy();
        socket.disconnect();
        setSyncStatus(null); // Reset sync status
      };
    }
  }, [BASE_URL, isEventLoading, isEventPassed]);

  useEffect(() => {
    console.log(`syncinc isEventLoading: ${isEventLoading}`);
    console.log(`syncinc isEventPassed: ${isEventPassed}`);

    if (!isEventLoading && !isEventPassed) {
      console.log('syncinc');
      // Immediate sync
      tsRef.current.sync();
    }
  }, [isEventLoading, isEventPassed]);

  useEffect(() => {
    if (!isEventLoading && !isEventPassed) {
      // Calculates Delta to launch music after syncing ends
      if (syncStatus === 'end' && scheduledTime && ntpOffset !== null && audioContext) {
        // console.log('isSynchronized = true');
        setIsSynchronized(true);
        const adjustedServerTime = new Date(Date.now() + ntpOffset);
        const deltaScheduledWServer =
          (new Date(scheduledTime).getTime() - adjustedServerTime.getTime()) / 1000;
        setDeltaScheduledWServer(deltaScheduledWServer);
        // const deltaAudioContextRef = deltaScheduledWServer + 2 * audioContext.currentTime;
        let deltaAudioContextRef;
        if (deltaScheduledWServer > 0) {
          // Starting music relative to the audioContext time
          deltaAudioContextRef = deltaScheduledWServer + audioContext.currentTime;
        } else {
          // Music is launched immediatly
          deltaAudioContextRef = deltaScheduledWServer;
        }
        setPlaybackDelta(deltaAudioContextRef);

        // If event has passed, perform 1 syncs at 5 seconds intervals
        // console.log(`Syncing 1 times : ${syncAttempts}`);
        if (syncAttempts < 1) {
          // console.log('Syncing');
          syncIntervalRef.current = window.setInterval(() => {
            if (syncAttempts < 1) {
              tsRef.current.sync();
              setSyncAttempts(attempts => attempts + 1);
            } else {
              if (syncIntervalRef.current !== undefined) {
                window.clearInterval(syncIntervalRef.current);
                syncIntervalRef.current = undefined;
              }
            }
          }, 5000);
        }
        // console.log(` Calculating Playback delta
        // \n UseNTP: (ntpOffset) : ${ntpOffset}
        // \n UseNTP: (AudioContextCurrent Time) : ${audioContext.currentTime}
        // \n UseNTP: (deltaScheduledWServer) : ${deltaScheduledWServer}
        // \n UseNTP: (Delta to AudioContextRef) : ${deltaAudioContextRef}
        // `);

        // Schedule Next sync
        let syncTimeout: ReturnType<typeof setTimeout> | undefined;
        if (deltaScheduledWServer > SYNC_BEFORE_MUSIC_STARTS + 10) {
          // If event hasn't started, sync again 5 minutes before scheduled time
          // console.log(`Event is in more than 5 minutes : ${deltaScheduledWServer / 60}`);
          syncTimeout = setTimeout(
            () => {
              console.log('Setting timeout');
              tsRef.current.sync();
            },
            (deltaScheduledWServer - SYNC_BEFORE_MUSIC_STARTS) * 1000 // ms
          ); // 5 minutes before event
        }

        // Clean up function
        return () => {
          if (syncIntervalRef.current) {
            clearInterval(syncIntervalRef.current);
          }
          if (syncTimeout) {
            clearTimeout(syncTimeout);
          }
        };
      }
    }
  }, [scheduledTime, audioContextState, syncStatus, isEventLoading, isEventPassed]);

  // If there is no network, the sync does not work
  useEffect(() => {
    // Checking network issue
    const timeoutId = setTimeout(() => {
      if (!isSynchronized) {
        setIsNetworkError(true);
      }
    }, TIMEOUT_NETWORK_ISSUE);

    return () => clearTimeout(timeoutId);
  }, [isSynchronized]);

  // PlaybackDelta is the playback delta in seconds compared to the audioContext current time (that can be different than 0)
  // deltaScheduledWServer is the difference between the scheduled time (when the music needs to start) and the current time (synchronized w server)
  return {
    isSynchronized,
    isNetworkError,
    ntpOffset,
    playbackDelta,
    deltaScheduledWServer,
  };
};

export default useNTPTime;
