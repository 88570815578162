import { useState, useEffect } from 'react';
import { API } from '../../../utils/API';

interface Settings {
  [key: string]: any;
}

interface useSettingsReturn {
  settings: Settings | null;
  setSettings: React.Dispatch<React.SetStateAction<Settings | null>>;
  error: any;
  isLoading: boolean;
}

export default function useSettings(): useSettingsReturn {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchAllSettings = async () => {
    try {
      setIsLoading(true);
      const settingsResponse = await API.get(`/api/v1/settings/`, {
        withCredentials: true,
      });
      // Assuming the API returns an array of settings
      const settingsData = settingsResponse.data;
      setSettings(settingsData);
    } catch (error) {
      console.error('Failed to fetch all settings:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Get Settings
  useEffect(() => {
    fetchAllSettings();
  }, []);

  return { settings, setSettings, error, isLoading };
}
