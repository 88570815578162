import React, { useEffect, useState, useRef } from 'react';
import { Snackbar, Typography, CircularProgress, Button } from '@mui/joy';
import { useTheme } from '@mui/joy/styles';
import { useTranslation } from 'react-i18next';

interface EventStatusSnackBarComponentProps {
  isSynchronized: boolean;
  isNetworkError: boolean;
}

const EventStatusSnackBarComponent: React.FC<EventStatusSnackBarComponentProps> = ({
  isSynchronized,
  isNetworkError,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const [iconType, setIconType] = useState<React.ReactNode>(null);
  const [applicationSnackbarBg, setApplicationSnackbarBg] = useState<string>(
    theme.palette.background.surface
  );
  const [iconColor, setIconColor] = useState<string | undefined>(undefined);

  const prevIsSynchronizedRef = useRef<boolean>(isSynchronized);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    const prevIsSynchronized = prevIsSynchronizedRef.current;

    if (isNetworkError) {
      // Display network error message
      setCurrentMessage(t('networkErrorMessage'));
      setIconType(
        <span className="material-symbols-outlined">
          signal_cellular_connected_no_internet_0_bar
        </span>
      );
      setApplicationSnackbarBg(theme.palette.danger[200]);
      setIconColor(theme.vars.palette.danger[500]);
      setOpen(true);
    } else if (!isSynchronized) {
      // Display synchronization message
      setCurrentMessage(t('synchronizationMessage'));
      setIconType(<CircularProgress size="sm" />);
      setApplicationSnackbarBg(theme.palette.background.surface);
      setIconColor(undefined);
      setOpen(true);
    } else if (isSynchronized && !prevIsSynchronized) {
      // Synchronization just completed successfully
      setCurrentMessage(t('synchronizationSuccessMessage'));
      setIconType(<span className="material-symbols-outlined">check_circle</span>);
      setApplicationSnackbarBg(theme.palette.success[200]);
      setIconColor(theme.vars.palette.success[500]);
      setOpen(true);

      // Automatically close after 5 seconds
      timer = setTimeout(() => {
        setOpen(false);
      }, 5000);
    } else {
      // No message to display
      setOpen(false);
    }

    // Update the previous isSynchronized value
    prevIsSynchronizedRef.current = isSynchronized;

    // Clean up the timer on component unmount or when dependencies change
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isSynchronized, isNetworkError, t, theme]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      variant="soft"
      sx={{
        backgroundColor: applicationSnackbarBg,
        display: 'flex',
        alignItems: 'center',
      }}
      endDecorator={
        isSynchronized && (
          <Button onClick={() => setOpen(false)} size="sm" variant="soft" color="success">
            {t('close')}
          </Button>
        )
      }
    >
      {iconType}
      <Typography
        sx={{
          color: iconColor,
          flexGrow: 1,
          textAlign: 'center',
          marginLeft: 1,
        }}
      >
        {currentMessage}
      </Typography>
    </Snackbar>
  );
};

export default EventStatusSnackBarComponent;
