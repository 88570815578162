import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography, CircularProgress, Button } from '@mui/joy';

import { useTheme } from '@mui/joy/styles';
import { formatDate, formatHour } from '../utils/formatUtils';
import Feedback from './SurveyComponent';
import { useTranslation } from 'react-i18next';

const MUSIC_PLAYED_DURATION = 60; // seconds -> Launch survey at the end of the music if the user played at least MUSIC_PLAYED_DURATION

interface MusicPlayerComponentProps {
  // isEventPassed: boolean;
  // isSynchronized: boolean;
  // isNetworkError: boolean;
  isMusicPlaying: boolean;
  isMusicFinished: boolean;
  isMuted: boolean;
  isMusicError: boolean;
  scheduledTime: Date | null;
  toggleMute: () => void;
}

const MusicPlayerComponent: React.FC<MusicPlayerComponentProps> = ({
  // isEventPassed,
  // isSynchronized,
  // isNetworkError,
  isMusicPlaying,
  isMusicFinished,
  isMuted,
  isMusicError,
  scheduledTime,
  toggleMute,
}) => {
  // PlaybackDelta is the playback delta in seconds compared to the audioContext current time (that can be different than 0)
  // deltaScheduledWServer is the difference between the scheduled time (when the music needs to start) and the current time (synchronized w server)
  // const [audioBuffer, setAudioBuffer] = useState(null);
  // const [isNetworkError, setIsNetworkError] = useState(false)
  const { t } = useTranslation();

  const theme = useTheme();
  // Format music player message depending on the delta Scheduled Time with server (provided by useNTPTime custom hook)
  const [musicListened, setMusicListened] = useState<boolean>(false);

  let musicMessage: string = '';
  let musicPlayerBgColor: string = theme.palette.background.surface;
  let iconType: JSX.Element | null = null;
  let iconColor: string = theme.palette.text.primary;

  // Making sure that the user has played at least 60 secondes of the music to show survey
  const musicStartTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (isMusicPlaying) {
      musicStartTimeRef.current = Date.now();
      const interval = setInterval(() => {
        if (musicStartTimeRef.current) {
          const elapsedTime = (Date.now() - musicStartTimeRef.current) / 1000;
          if (elapsedTime >= MUSIC_PLAYED_DURATION) {
            setMusicListened(true);
            clearInterval(interval);
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    } else {
      musicStartTimeRef.current = null;
    }
  }, [isMusicPlaying]);

  // // Determine state and corresponding UI elements
  if (isMusicError) {
    musicMessage = t('musicLoadingError');
    iconType = <span className="material-symbols-outlined">media_output_off</span>;
    musicPlayerBgColor = theme.palette.danger[200];
  } else if (isMusicFinished) {
    musicMessage = t('eventFinishedMessage');
    iconType = <span className="material-symbols-outlined">event_busy</span>;
  } else if (isMusicPlaying && !isMuted) {
    musicMessage = t('musicInProgressMessage');
    iconColor = theme.palette.text.white;
    iconType = (
      <IconButton
        sx={{
          backgroundColor: theme.palette.primary.softActiveBg,
          color: iconColor,
          borderRadius: '50%',
          border: `1px solid`,
          borderColor: 'divider',
        }}
        onClick={toggleMute}
      >
        <span className="material-symbols-outlined">volume_up</span>
      </IconButton>
    );
    musicPlayerBgColor = theme.palette.primary[500];
  } else if (isMusicPlaying && isMuted) {
    musicMessage = t('musicInProgressMutedMessage');
    iconColor = theme.palette.text.white;
    iconType = (
      <IconButton
        sx={{
          backgroundColor: theme.palette.primary[600],
          color: iconColor,
          borderRadius: '50%',
          border: '1px solid',
          borderColor: 'divider',
        }}
        onClick={toggleMute}
      >
        <span className="material-symbols-outlined">volume_off</span>
      </IconButton>
    );
    musicPlayerBgColor = theme.palette.primary.accentColor;
  } else if (!isMusicPlaying && !isMusicFinished) {
    // console.log(scheduledTime)
    const now = new Date();
    if (scheduledTime) {
      // const dateScheduledTime = new Date(scheduledTime);
      const isToday =
        scheduledTime.getDate() === now.getDate() &&
        scheduledTime.getMonth() === now.getMonth() &&
        scheduledTime.getFullYear() === now.getFullYear();
      if (isToday) {
        musicMessage = t('beforeMusicStartMessage', {
          scheduledHour: formatHour(scheduledTime.toISOString()),
        });
      } else {
        musicMessage = t('beforeLongMusicStartMessage', {
          scheduledTime: formatDate(scheduledTime.toISOString()),
        });
      }
    } else {
      musicMessage = t('loading');
    }
    iconType = <span className="material-symbols-outlined">schedule</span>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 1,
        py: 1,
        width: '100%',
        minHeight: '56px',
        // borderRadius: 'md',
        backgroundColor: musicPlayerBgColor,
        borderTop: `1px solid`,
        borderColor: 'divider',
      }}
    >
      {isMusicFinished && musicListened && (
        <>
          <Feedback />
        </>
      )}

      {/* icon or mute button if music playing */}
      {iconType}

      {/* Text + Gif if music is playing */}
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        {isMusicPlaying && isMuted && (
          <img
            src="/music_playing_light.gif"
            alt="music notes"
            style={{
              display: 'block',
              margin: 0,
              width: 40,
              height: 40,
              objectFit: 'cover',
              padding: 0,
            }}
          />
        )}
        {isMusicPlaying && !isMuted && (
          <img
            src="/music_playing_dark.gif"
            alt="music notes"
            style={{
              display: 'block',
              margin: 0,
              width: 40,
              height: 40,
              objectFit: 'cover',
              padding: 0,
            }}
          />
        )}
        <Typography
          sx={{
            // marginLeft: '1em',
            color: iconColor,
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          {musicMessage}
        </Typography>
      </Box>

      {/*  Placeholder so that the message is in the middle */}
      <Box sx={{ width: '40px', height: '40px' }} />
    </Box>
  );
};

export default MusicPlayerComponent;
