import { useCallback } from 'react';
import { API } from '../../../utils/API';

const useTriggerNotification = () => {
  const triggerWebPushNotification = useCallback(async (eventId: string) => {
    try {
      console.log('Launching Notification');
      await API.post('/api/v1/notifications/send', {
        eventId,
      });
    } catch (err) {
      console.error('Error triggering web push notification:', err);
    }
  }, []);

  return { triggerWebPushNotification };
};

export default useTriggerNotification;
