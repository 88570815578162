// src/views/adminevents/components/FileUploadButton.tsx

import React, { useState, useRef } from 'react';
import { Button, Box, Typography, LinearProgress, CircularProgress } from '@mui/joy';

interface ErrorMessages {
  [key: string]: string;
}

interface FileUploadComponentProps {
  label: string;
  variant?: 'solid' | 'plain' | 'outlined' | 'soft';
  color?: 'primary' | 'neutral' | 'danger' | 'success' | 'warning';
  allowedTypes: string[];
  maxSizeMB: number;
  onFileSelected: (file: File) => void;
  errorMessages: ErrorMessages;
  uploadProgress: number;
  isUploading: boolean;
  uploadErrorMessage: string | null;
  initialFileName?: string;
}

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  label,
  variant = 'solid',
  color = 'primary',
  allowedTypes,
  maxSizeMB,
  onFileSelected,
  uploadProgress,
  isUploading,
  uploadErrorMessage,
  errorMessages,
  initialFileName,
}) => {
  // const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>(initialFileName || '');
  const [validationError, setValidationError] = useState<string | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    setValidationError(null);
    // setFile(null);

    if (selectedFile) {
      // Validate file size
      const fileSizeMB = selectedFile.size / (1024 * 1024);
      if (fileSizeMB > maxSizeMB) {
        const customErrorMessage =
          errorMessages['UPLOAD_FILE_TOO_BIG'] || 'File is too big';
        setValidationError(customErrorMessage);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }

      // Validate file type
      if (!allowedTypes.includes(selectedFile.type)) {
        const customErrorMessage =
          errorMessages['UPLOAD_INVALID_FILE_TYPE'] || 'Invalid file type';
        setValidationError(customErrorMessage);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }
      // File is valid
      // setFile(selectedFile);
      setFileName(selectedFile.name);
      onFileSelected(selectedFile);
    }
  };

  return (
    <Box>
      <Button
        component="label"
        variant={variant}
        color={color}
        sx={{ justifyContent: 'flex-start' }}
        disabled={isUploading}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
          {isUploading ? (
            <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
          ) : (
            <span className="material-symbols-outlined" style={{ paddingRight: '8px' }}>
              upload_file
            </span>
          )}
          <Typography>{fileName ? ` | ${fileName}` : `| ${label}`}</Typography>
        </Box>
        <input type="file" hidden ref={fileInputRef} onChange={handleFileChange} />
      </Button>
      {isUploading && (
        <LinearProgress
          size="md"
          variant="outlined"
          color="primary"
          determinate
          value={uploadProgress}
        />
      )}
      {validationError && (
        <Typography color="danger" fontSize="sm">
          {validationError}
        </Typography>
      )}
      {uploadErrorMessage && (
        <Typography color="danger" fontSize="sm">
          {uploadErrorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default FileUploadComponent;
