import { useState, useCallback } from 'react';
import axios from 'axios';

interface City {
  nom: string;
  code: string;
  codeDepartement: string;
  codeRegion: string;
  population: number;
}

interface useCityAutocompleteReturn {
  citiesOption: City[];
  isLoadingLocation: boolean;
  fetchCities: (searchTerm: string) => void;
}

export default function useCityAutocomplete(): useCityAutocompleteReturn {
  const [citiesOption, setCitiesOption] = useState<City[]>([]);
  const [isLoadingLocation, setIsLoadingLocation] = useState<boolean>(false);

  const fetchCities = useCallback((searchTerm: string) => {
    if (!searchTerm) {
      setCitiesOption([]);
      return;
    }

    setIsLoadingLocation(true);

    const apiUrl = `https://geo.api.gouv.fr/communes?nom=${encodeURIComponent(
      searchTerm
    )}&fields=nom,code,codeDepartement,codeRegion,population&limit=5`;

    axios
      .get<City[]>(apiUrl)
      .then(response => {
        setCitiesOption(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch cities:', error);
        setCitiesOption([]);
      })
      .finally(() => {
        setIsLoadingLocation(false);
      });
  }, []);

  return { citiesOption, isLoadingLocation, fetchCities };
}
