// src/utils/NotificationModel.js
export async function requestNotificationPermission(): Promise<boolean> {
  if ('Notification' in window && 'serviceWorker' in navigator) {
    const permission = await Notification.requestPermission();
    return permission === 'granted';
  }
  return false;
}

// ScheduleNotification is not used
export function scheduleNotification(
  title: string,
  options: NotificationOptions,
  delay: number,
  url: string
): void {
  setTimeout(() => {
    if ('serviceWorker' in navigator && 'Notification' in window) {
      if (Notification.permission === 'granted') {
        navigator.serviceWorker.ready
          .then(registration => {
            registration.showNotification(title, {
              ...options,
              data: { url },
            });
          })
          .catch(err => {
            console.error('Service Worker not ready:', err);
          });
      }
    } else {
      new Notification(title, {
        ...options,
        data: { url },
      });
    }
  }, delay);
}
