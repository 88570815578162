// src/views/adminevents/hooks/useEventPayment.ts
import { API } from '../../../utils/API';

interface UseEventPaymentReturn {
  initiatePayment: (eventId: string) => Promise<void>;
}

export default function useEventPayment(): UseEventPaymentReturn {
  const initiatePayment = async (eventId: string) => {
    try {
      const response = await API.post(
        `/api/v1/payment/checkout/${eventId}`,
        {},
        { withCredentials: true }
      );
      window.location.href = response.data.url;
    } catch (error: any) {
      console.error('Failed to initiate payment:', error);
    }
  };

  return { initiatePayment };
}
