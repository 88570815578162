import { Autocomplete, CircularProgress, FormControl, Typography } from '@mui/joy';
import { API } from '../../../utils/API';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomFormLabel from '../../../components/CustomFormLabel';
import useCityAutocomplete from '../hooks/useCityAutocomplete';

interface LocationComponentProps {
  onLocationSelect: (location: string) => void;
  isEditing: boolean;
  initialLocation: string;
  setPublicLinkWarningMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationComponent: React.FC<LocationComponentProps> = ({
  onLocationSelect,
  isEditing,
  initialLocation,
  setPublicLinkWarningMessage,
}) => {
  const { t } = useTranslation();
  const [isLocationSelected, setIsLocationSelected] = useState<boolean>(false);
  const [eventPrice, setEventPrice] = useState<number | null>(null);

  const [inputCity, setInputCity] = useState<string>(initialLocation);

  // hasTouched state to track whether the user has interacted with the location input field
  const [hasTouched, setHasTouched] = useState(false);

  const { citiesOption, isLoadingLocation, fetchCities } = useCityAutocomplete();

  // Debounce the API call
  useEffect(() => {
    if (inputCity === '' || isLocationSelected) return;

    const timer = setTimeout(() => {
      console.log('fetching cities');
      fetchCities(inputCity);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [inputCity, fetchCities, isLocationSelected]);

  // If is Editing to fill the input selector
  useEffect(() => {
    setInputCity(initialLocation);
    const isValidLocation = initialLocation !== '';
    setIsLocationSelected(isValidLocation);
    setHasTouched(false);
  }, [initialLocation]);

  const handleGetPrice = async (location: string) => {
    try {
      const response = await API.get(
        `/api/v1/events/price/${encodeURIComponent(location)}`,
        {
          withCredentials: true,
        }
      );
      const calculatedPrice = response.data.calculatedPrice / 100;
      setEventPrice(calculatedPrice);
    } catch (error) {
      console.error('Failed to get event price:', error);
    }
  };

  return (
    <>
      <FormControl>
        <CustomFormLabel
          htmlFor="event-location"
          label={t('city')}
          optional={false}
          flyover={true}
          flyoverText={t('cityDescription')}
        />
        <Autocomplete
          freeSolo
          inputValue={inputCity}
          onInputChange={(_, newInputCity, reason) => {
            if (reason === 'input') {
              setInputCity(newInputCity);
              setIsLocationSelected(false);
              setHasTouched(true);
            } else if (reason === 'clear') {
              setInputCity('');
              setIsLocationSelected(false);
              setHasTouched(false);
            }
          }}
          onChange={(_, newCity) => {
            setHasTouched(true);
            if (newCity && typeof newCity === 'object' && newCity.nom) {
              const newLocation = newCity.nom;
              setInputCity(newLocation);
              setIsLocationSelected(true);
              onLocationSelect(newLocation);
              handleGetPrice(newLocation);
            } else if (typeof newCity === 'string') {
              setInputCity(newCity);
              setIsLocationSelected(false);
            } else {
              setInputCity('');
              setIsLocationSelected(false);
            }
            if (isEditing) {
              setPublicLinkWarningMessage(true);
            }
          }}
          options={citiesOption}
          getOptionLabel={option => (typeof option === 'string' ? option : option.nom)}
          loading={isLoadingLocation}
          required
          endDecorator={
            isLoadingLocation ? (
              <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
            ) : null
          }
          sx={{ minWidth: 300 }}
        />
        {/* Display Validation Error if wrong text entered, make sure user "has touched" the autocomplete button*/}
        {!isLocationSelected && hasTouched && (
          <Typography color="danger" fontSize="sm">
            {t('cityError')}
          </Typography>
        )}
      </FormControl>
      {eventPrice !== null && (
        <Typography sx={{ mt: 1 }}>
          {t('eventPriceInformation', { eventPrice })}
        </Typography>
      )}
    </>
  );
};

export default LocationComponent;
