// src/hooks/useFileUpload.ts

import { useState } from 'react';
import { API } from '../utils/API';

interface UploadError {
  code: string;
  message: string;
}

interface UseFileUploadProps {
  userBusinessId: string | null;
  onProgress?: (progress: number) => void;
  onError?: (error: UploadError) => void;
  onSuccess?: (filename: string) => void;
}

interface UseFileUploadReturn {
  uploadFile: (file: File) => Promise<boolean>;
  uploadProgress: number;
  error: UploadError | null;
  isUploading: boolean;
}

export default function useFileUpload({
  userBusinessId,
  onProgress,
  onError,
  onSuccess,
}: UseFileUploadProps): UseFileUploadReturn {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [error, setError] = useState<UploadError | null>(null);

  const MAX_RETRIES: number = 3;
  const RETRY_DELAY = 2000;

  const uploadFile = async (file: File): Promise<boolean> => {
    const CHUNK_SIZE = 1024 * 1024; // 1 MB
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    const originalMimeType = file.type;
    let start = 0;

    setIsUploading(true);
    setError(null);

    try {
      for (let index = 0; index < totalChunks; index++) {
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);

        const chunkUploadSuccess = await uploadChunk(
          file.name,
          chunk,
          index,
          totalChunks,
          originalMimeType
        );

        if (!chunkUploadSuccess) {
          throw new Error('Network issue');
        }

        const progress = ((index + 1) / totalChunks) * 100;
        setUploadProgress(progress);
        if (onProgress) onProgress(progress);

        start = end;
      }

      setUploadProgress(100);
      if (onSuccess) onSuccess(file.name);
      return true;
    } catch (err: any) {
      const errorCode = err.response?.data?.error || 'UNKNOWN_ERROR';
      const uploadError: UploadError = {
        code: errorCode,
        message: err.message || 'Unexpected upload error',
      };

      setError(uploadError);
      if (onError) onError(uploadError);
      return false;
    } finally {
      setIsUploading(false);
    }
  };

  const uploadChunk = async (
    filename: string,
    chunk: Blob,
    chunkIndex: number,
    totalChunks: number,
    mimeType: string
  ): Promise<boolean> => {
    const formData = new FormData();
    formData.append('file', chunk);
    formData.append('index', chunkIndex.toString());
    formData.append('total', totalChunks.toString());
    formData.append('mimeType', mimeType);
    formData.append('originalName', filename);

    for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
      try {
        await API.post(
          `/api/v1/file/upload/${userBusinessId}?mimeType=${mimeType}&index=${chunkIndex}&originalName=${filename}`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          }
        );
        return true;
      } catch (error: any) {
        if (error.response?.data?.error === 'UPLOAD_INVALID_FILE') {
          throw error;
        }
        if (attempt < MAX_RETRIES - 1) {
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        }
      }
    }
    return false;
  };

  return {
    uploadFile,
    uploadProgress,
    error,
    isUploading,
  };
}
