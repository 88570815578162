// src/hooks/useEvent.js

import useEventInfo, { UseEventInfoReturn } from './useEventInfo';
import useNTPTime, { UseNTPTimeReturn } from './useNTPTime';
import useEventAudio, { UseEventAudioReturn } from './useEventAudio';

interface UseEventProps {
  eventIdentifier: string;
  audioContext: AudioContext | null;
  audioContextState: AudioContextState | null;
  isTestMode?: boolean;
  isPyroPage?: boolean;
}

interface UseEventReturn
  extends UseEventInfoReturn,
    UseNTPTimeReturn,
    UseEventAudioReturn {}

const useEvent = (params: UseEventProps): UseEventReturn => {
  const {
    eventIdentifier,
    audioContext,
    audioContextState,
    isTestMode = false,
    isPyroPage = false,
  } = params;

  // Fetch event information
  const {
    eventInfo,
    scheduledTime,
    testTime,
    eventError,
    isArmed,
    isEventPassed,
    isEventLoading,
  } = useEventInfo(isPyroPage ? `pyro/${eventIdentifier}` : `public/${eventIdentifier}`);

  // Determine the event time
  const eventTime = isTestMode ? testTime : scheduledTime;
  const isEventArmed = isTestMode ? true : isArmed; // launch music if isArmed when not in test Mode

  // Handle time synchronization
  const {
    isSynchronized,
    isNetworkError,
    ntpOffset,
    playbackDelta,
    deltaScheduledWServer,
  } = useNTPTime(
    audioContext,
    audioContextState,
    eventTime,
    isEventLoading,
    isEventPassed
  );

  // Prepare audio parameters
  const musicPath = eventInfo?.musicPath ?? '';
  const isTimecode = eventInfo?.isTimecode ?? false;
  const timecodeChannel = eventInfo?.timecodeChannel ?? false;

  // Manage audio playback
  const { isMusicPlaying, isMusicFinished, toggleMute, isMuted, isMusicError } =
    useEventAudio(
      musicPath,
      audioContext,
      playbackDelta,
      deltaScheduledWServer,
      isTimecode,
      timecodeChannel,
      isEventArmed,
      isEventLoading,
      isPyroPage // For timecode splitting
    );

  return {
    eventInfo,
    scheduledTime,
    testTime,
    eventError,
    isArmed,
    isEventPassed,
    ntpOffset,
    isSynchronized,
    playbackDelta,
    deltaScheduledWServer,
    isEventLoading,
    isMusicPlaying,
    isMusicFinished,
    toggleMute,
    isMuted,
    isMusicError,
    isNetworkError,
  };
};

export default useEvent;
