// src/contexts/WebSocketContext.tsx

import React, { createContext, useEffect, useState, ReactNode } from 'react';
import WebSocketService from '../utils/websocketService';
import { Socket } from 'socket.io-client';

import { BASE_URL } from '../utils/API';

interface WebSocketContextProps {
  socket: Socket | null;
}

export const WebSocketContext = createContext<WebSocketContextProps>({
  socket: null,
});

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const socketService = WebSocketService.getInstance();
    const connectedSocket = socketService.connect(BASE_URL);
    setSocket(connectedSocket);

    // connectedSocket.on('connect', () => {
    //   console.log('WebSocket connected with id:', connectedSocket.id);
    // });

    // connectedSocket.on('disconnect', () => {
    //   console.log('WebSocket disconnected.');
    // });

    // connectedSocket.on('connect_error', error => {
    //   console.error('WebSocket connect error:', error);
    // });

    return () => {
      // Clean up socket listeners and disconnect
      connectedSocket.off('connect');
      connectedSocket.off('disconnect');
      connectedSocket.off('connect_error');
      socketService.disconnect();
      setSocket(null);
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket }}>{children}</WebSocketContext.Provider>
  );
};
