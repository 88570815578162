import { useState, useEffect } from 'react';
import axios from 'axios';
import { BusinessInfo } from '../../../types/businessTypes';
import { EventInfo } from '../../../types/eventTypes';
import { API } from '../../../utils/API';

interface EventsbyBusinessReturn {
  events: EventInfo[];
  isLoading: boolean;
  error: any;
}

export default function useEventsbyBusiness(
  businessShortName: string | undefined
): EventsbyBusinessReturn {
  const [events, setEvents] = useState<EventInfo[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchBusinessEvents = async () => {
    try {
      const response = await API.get(`/api/v1/events/list/${businessShortName}`);
      const sortedEvents = response.data.sort((a: EventInfo, b: EventInfo) => {
        const dateA = a.scheduledTime ? new Date(a.scheduledTime).getTime() : 0;
        const dateB = b.scheduledTime ? new Date(b.scheduledTime).getTime() : 0;
        return dateA - dateB;
      });
      setEvents(sortedEvents);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (businessShortName) {
      fetchBusinessEvents();
    }
  }, [businessShortName]);

  return { events, isLoading, error };
}
