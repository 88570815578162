import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Text

import { API } from '../../utils/API';

import useSettings from './hooks/useSettings';
import SideBarComponent from '../../components/SideBarComponent';
import { formatLabel } from '../../utils/formatUtils';

import { CssBaseline, Box, Button, Stack, FormLabel, Input } from '@mui/joy';

interface GlobalSettingsPageProps {}

const GlobalSettingsPage: React.FC<GlobalSettingsPageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [minimumFee, setMinimumFee] = useState([]);
  const [feePerHabitant, setFeePerHabitant] = useState([]);
  // Get Event information from API & construct Music path

  const { settings, setSettings, error, isLoading } = useSettings();

  const handleInputChange = (key: string, value: any) => {
    if (settings) {
      setSettings({
        ...settings,
        [key]: value,
      });
    }
  };

  const handleSettingsUpdate = async () => {
    if (settings) {
      try {
        // Transform settings object into an array
        const settingsArray = Object.entries(settings).map(([key, value]) => ({
          setting_key: key,
          value,
        }));

        await API.put(`/api/v1/settings/`, settingsArray, {
          withCredentials: true,
        });

        navigate('/admin/events/');
      } catch (error) {
        console.error('Failed to update settings:', error);
        // Handle error appropriately
      }
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SideBarComponent settings={false} />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          padding: '1em',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
            <h2>{t('settings')}</h2>
          </Box>
          <Stack
            className="settings-main"
            justifyContent="left"
            alignItems="left"
            spacing={2}
          >
            {settings &&
              Object.keys(settings).map(key => (
                <Box key={key}>
                  <FormLabel>{t(formatLabel(key))}</FormLabel>
                  <Input
                    placeholder={t(formatLabel(key))}
                    value={settings[key]}
                    onChange={e => handleInputChange(key, e.target.value)}
                    sx={{ maxWidth: '120px' }}
                  />
                </Box>
              ))}
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, gap: 1 }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/admin/events');
            }}
          >
            {t('cancel')}
          </Button>
          <Button variant="soft" onClick={handleSettingsUpdate}>
            {t('save')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GlobalSettingsPage;
