// AuthContext.js
import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { API } from '../utils/API';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  isLoading: boolean;
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  userEmail: string | null;
  setUserEmail: Dispatch<SetStateAction<string | null>>;
  userRole: string | null;
  setUserRole: Dispatch<SetStateAction<string | null>>;
  userBusinessId: string | null;
  setUserBusinessId: Dispatch<SetStateAction<string | null>>;
  userBusinessName: string | null;
  setUserBusinessName: Dispatch<SetStateAction<string | null>>;
  handleLogout: () => Promise<void>; // Added logout function to the context type
}

const defaultState: AuthContextType = {
  isLoading: true,
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  userEmail: null,
  setUserEmail: () => {},
  userRole: null,
  setUserRole: () => {},
  userBusinessId: null,
  setUserBusinessId: () => {},
  userBusinessName: null,
  setUserBusinessName: () => {},
  handleLogout: async () => {},
};

interface AuthProviderProps {
  children: ReactNode;
  isPublic: boolean;
}

interface AuthResponseData {
  email: string;
  role: string;
  businessId: string;
  businessName: string;
}

export const AuthContext = createContext<AuthContextType>(defaultState);

export const AuthProvider = ({ children, isPublic }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return localStorage.getItem('authToken') !== null;
  });

  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [userBusinessId, setUserBusinessId] = useState<string | null>(null);
  const [userBusinessName, setUserBusinessName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await API.post(`/api/v1/auth/logout`, {}, { withCredentials: true });
      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
      setUserEmail(null);
      setUserRole(null);
      setUserBusinessId(null);
      setUserBusinessName(null);
      navigate('/signin');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  useEffect(() => {
    // Function to check authentication status
    const checkAuthStatus = async () => {
      try {
        // console.log('CheckAuthStatus')
        const response = await API.get<AuthResponseData>(`/api/v1/auth/me`, {
          withCredentials: true,
        });
        // console.log(response)
        setIsAuthenticated(response.status === 200);
        setUserEmail(response.data.email);
        setUserRole(response.data.role);
        setUserBusinessId(response.data.businessId);
        setUserBusinessName(response.data.businessName);
        // console.log(`
        // ${response.data.email}
        // \n ${response.data.role}
        // \n ${response.data.businessId}
        // `)
      } catch (error: any) {
        console.log(`Auth Context : ${error}`);
        setIsAuthenticated(false);
        localStorage.removeItem('authToken');
        setUserEmail(null);
        setUserRole(null);
        setUserBusinessId(null);
        setUserBusinessName(null);
      } finally {
        setIsLoading(false);
      }
    };
    if (!isPublic) {
      checkAuthStatus();
    } else {
      setIsLoading(false);
    }
  });

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        setIsAuthenticated,
        userEmail,
        setUserEmail,
        userRole,
        setUserRole,
        userBusinessId,
        setUserBusinessId,
        userBusinessName,
        setUserBusinessName,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
