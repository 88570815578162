import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';

import { PostHogProvider } from 'posthog-js/react';

// Importing Orbiner Theme to the application
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import { materialTheme, joyTheme } from './theme';

import CssBaseline from '@mui/joy/CssBaseline';

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  opt_in_site_apps: true,
};

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/serviceworker.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}

root.render(
  <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
    <JoyCssVarsProvider theme={joyTheme}>
      <CssBaseline enableColorScheme />
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </PostHogProvider>
    </JoyCssVarsProvider>
  </MaterialCssVarsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
