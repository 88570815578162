import React, { useState, useEffect } from 'react';

import { Typography, Stack, Box } from '@mui/joy';
import { formatSeconds } from '../../../utils/formatUtils';

interface CountDownComponentProps {
  ntpOffset: number;
  scheduledTime: Date;
}

const CountdownComponent: React.FC<CountDownComponentProps> = ({
  ntpOffset,
  scheduledTime,
}) => {
  const [hoursDaysMessage, setHoursDaysMessage] = useState<string>('');
  const [countDown, setCountDown] = useState<string>('');

  useEffect(() => {
    let timerInterval: NodeJS.Timeout | undefined;
    const updateCountdown = () => {
      const timeNow = Date.now();
      const ntpTime = new Date(timeNow + ntpOffset);
      const timeDifference = ntpTime.getTime() - scheduledTime.getTime();
      setCountDown(formatCountdown(timeDifference));
      adjustInterval(timeDifference);
    };

    // Adjust interval depending on the timedifference
    const adjustInterval = (timeDifference: number) => {
      clearInterval(timerInterval);
      if (timeDifference >= -10000 && timeDifference <= 0) {
        // 10 seconds before the event it shows every 10 ms
        timerInterval = setInterval(updateCountdown, 10);
      } else {
        // Countdown every 1 seconds
        timerInterval = setInterval(updateCountdown, 1000);
      }
    };

    const formatCountdown = (timeDifference: number) => {
      // console.log(`Countdown : ${timeDifference}`)
      let sign: string =
        timeDifference < 0 ? '-' : timeDifference > 0 ? '\u00A0' : '\u00A0';
      let absTime: number = Math.abs(timeDifference);
      let minutes: number = Math.floor((absTime / (1000 * 60)) % 60);
      let seconds: number = Math.floor((absTime / 1000) % 60);
      let milliseconds: number = Math.floor(absTime % 1000);

      const minutesStr: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const secondsStr: string = seconds < 10 ? `0${seconds}` : `${seconds}`;
      const millisecondsStr: string =
        milliseconds < 100
          ? milliseconds < 10
            ? `00${milliseconds}`
            : `0${milliseconds}`
          : `${milliseconds}`;

      if (timeDifference >= -10000 && timeDifference <= 0) {
        return `${sign}${minutesStr}:${secondsStr}.${millisecondsStr}`;
      } else {
        return `${sign}${minutesStr}:${secondsStr}`;
      }
    };

    // initialize countdown
    if (ntpOffset !== null && scheduledTime !== null) {
      updateCountdown();
    }
    return () => clearInterval(timerInterval);
  }, [ntpOffset, scheduledTime]);

  // Showing Days & Hours on top of countdown
  useEffect(() => {
    if (ntpOffset !== null && scheduledTime !== null) {
      const timeNow = Date.now();
      const ntpTime = new Date(timeNow + ntpOffset);
      const deltaTime = ntpTime.getTime() - scheduledTime.getTime();
      setHoursDaysMessage(formatSeconds(-deltaTime / 1000, false, false));
      const setIntervalDaysandHours = setInterval(() => {
        const timeNow = Date.now();
        const ntpTime = new Date(timeNow + ntpOffset);
        const deltaTime = ntpTime.getTime() - scheduledTime.getTime();
        setHoursDaysMessage(formatSeconds(-deltaTime / 1000, false, false));
      }, 60000); // Check every 10 min
      return () => clearInterval(setIntervalDaysandHours);
    }
  }, [ntpOffset, scheduledTime]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          p: 2,
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            // fontSize: 'xl',
            lineHeight: '100%',
            fontSize: 'min(4em,10vw)', // Make font size responsive
            maxWidth: '100%',
            fontFamily: '"Roboto Mono", monospace',
            flexGrow: 1,
          }}
        >
          {hoursDaysMessage}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100%' }}
        >
          <Typography
            level="h1"
            sx={{
              fontSize: 'min(8em,20vw)',
              lineHeight: '150%',
              maxWidth: '100%',
              fontFamily: '"Roboto Mono", monospace',
              textAlign: 'center',
            }}
          >
            {countDown}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default CountdownComponent;
