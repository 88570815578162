import React, { useState } from 'react';
import {
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Button,
  Input,
  Stack,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/joy';

import { useTranslation } from 'react-i18next';

import BusinessSelectComponent from './components/BusinessSelectComponent';
import { BusinessInfo } from '../../types/businessTypes';
import useUserForm from './hooks/useUserForm';

interface UserModalComponentProps {
  open: boolean;
  handleClose: () => void;
  businesses: BusinessInfo[];
}

const UserModalComponent: React.FC<UserModalComponentProps> = ({
  open,
  handleClose,
  businesses,
}) => {
  // const [email, setEmail] = useState<string>('');
  // const [password, setPassword] = useState<string>('');
  // const [selectedBusiness, setSelectedBusiness] = useState<BusinessInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const { t } = useTranslation();

  const onSuccess = () => {
    handleClose();
  };

  const onError = (error: any) => {
    console.log(error);
    // // Check if the error response has data and error messages
    // if (error.response && error.response.data && error.response.data.details.errors) {
    //   const errorDetails = error.response.data.details.errors;
    //   const newErrors: { [key: string]: string[] } = {};
    //   errorDetails.forEach((err: any) => {
    //     if (err.param && err.msg) {
    //       // Initialize array if it doesn't exist
    //       if (!newErrors[err.param]) {
    //         newErrors[err.param] = [];
    //       }
    //       newErrors[err.param].push(err.msg);
    //     }
    //   });
    //   setErrors(newErrors);
    // } else {
    //   setErrors({ general: [t('unexpectedError')] }); // Convert string to array
    // }
  };

  const onLoading = (loading: boolean) => {
    setIsLoading(loading);
  };

  const { formState, handleInputChange, handleSubmit, handleBusinessSelect, errors } =
    useUserForm({
      onSuccess,
      onError,
      onLoading,
    });

  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  // Validate mandatory fields (email  and password)
  const isFormValid =
    formState.email.trim() !== '' &&
    formState.password.trim() !== '' &&
    formState.businessId.trim() !== '' &&
    Object.values(errors).every(errorArray => errorArray.length === 0);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="user-modal-title"
      aria-describedby="user-modal-description"
      sx={{ backdropFilter: 'blur(10px)' }}
    >
      <ModalDialog
        variant="outlined"
        color="primary"
        sx={{ overflow: 'hidden' }} // This ensures that the DialogContent does not have any additional space around it
      >
        <DialogTitle>{t('createUser')}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel htmlFor="user-email">Email</FormLabel>
                <Input
                  id="user-email"
                  value={formState.email}
                  onChange={e => handleInputChange('email', e.target.value)}
                  required
                  error={errors.email?.length > 0}
                />
                {errors.email &&
                  errors.email.map((error, index) => (
                    <Typography key={index} color="danger" fontSize="sm">
                      {error}
                    </Typography>
                  ))}
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="user-password">{t('password')}</FormLabel>
                <Input
                  id="user-password"
                  type="password"
                  value={formState.password}
                  onChange={e => handleInputChange('password', e.target.value)}
                  required
                  error={errors.password?.length > 0}
                />
                {errors.password &&
                  errors.password.map((error, index) => (
                    <Typography key={index} color="danger" fontSize="sm">
                      {error}
                    </Typography>
                  ))}
              </FormControl>
              <BusinessSelectComponent
                businesses={businesses}
                selectedBusinessId={formState.businessId}
                onBusinessSelect={handleBusinessSelect}
                error={errors.businessId?.length > 0}
              />
              {errors.businessId &&
                errors.businessId.map((error, index) => (
                  <Typography key={index} color="danger" fontSize="sm">
                    {error}
                  </Typography>
                ))}
              {errors.general &&
                errors.general.map((error, index) => (
                  <Typography key={index} color="danger" fontSize="sm">
                    {error}
                  </Typography>
                ))}
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="plain" color="neutral" onClick={handleClose}>
                  {t('cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={!isFormValid || isLoading}
                >
                  {t('create')}
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};
//
export default UserModalComponent;
