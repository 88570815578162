import React, { useState } from 'react';
import { API } from '../../utils/API';
import { useTranslation } from 'react-i18next'; // Text

import SideBarComponent from '../../components/SideBarComponent';

import UsersTable from './components/UsersTable';
import UserModalComponent from './UserModalComponent';

import useUserList from './hooks/useUserList';

import { CssBaseline, Box, Button } from '@mui/joy'; // Use Joy UI components

interface AdminUsersPageProps {}

const AdminUsersPage: React.FC<AdminUsersPageProps> = () => {
  const { t } = useTranslation();

  // const [businesses, setBusinesses] = useState([]);

  // const [isEditing, setIsEditing] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);

  // Get List of Users
  const { users, businesses, loading, error } = useUserList();

  // TODO : edit adminusersPage
  // Function to close the modal
  const handleCreateUser = () => {
    // setIsEditing(false);
    setUserModalOpen(true);
    console.log('Add User clicked');
  };

  // // Placeholder function for editing user
  // const handleEditUser = selectedUserId => {
  //   setIsEditing(true);
  //   setUserModalOpen(true); // Open the modal
  //   // console.log(`Edit User ID: ${selectedUserId}`);
  //   // const userToEdit = users.find(user => user.id === selectedUserId);
  //   // setSelectedUser(userToEdit); // Set the selected user
  // };

  const handleDeleteUser = async (selectedUserId: string) => {
    // console.log(`Delete User ID: ${selectedUserId}`);
    await API.delete(`/api/v1/users/${selectedUserId}`, {
      withCredentials: true,
    });
    // setUsers(users.filter(user => user.id !== selectedUserId));

    // setIsEditing(true)
    // console.log(`Edit User ID: ${selectedUserId}`);
    // const userToEdit = users.find(user => user.id === selectedUserId);
    // setSelectedUser(userToEdit); // Set the selected user
  };

  // Function to close the modal
  const handleUserCloseModal = () => {
    setUserModalOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SideBarComponent settings={false} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: '1em',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
          <h2>{t('users')}</h2>
          <Button onClick={handleCreateUser} variant="soft" color="primary">
            {t('createUser')}
          </Button>
        </Box>
        <Box className="admin-table">
          {users.length > 0 ? (
            <UsersTable
              users={users}
              // onEditUser={handleEditUser}
              onDeleteUser={handleDeleteUser}
            />
          ) : (
            <div>{t('noUsers')}</div>
          )}
        </Box>
        <UserModalComponent
          open={userModalOpen}
          handleClose={handleUserCloseModal}
          businesses={businesses}
        />
      </Box>
    </Box>
  );
};

export default AdminUsersPage;
