// src/components/CustomDrawer.tsx

import React from 'react';
import Drawer from '@mui/joy/Drawer';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

import useLoginForm from '../hooks/useLoginForm';
import { useTranslation } from 'react-i18next';

interface CustomDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const LoginDrawerComponent: React.FC<CustomDrawerProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { formState, handleInputChange, handleLogin, errorMessage, isLoading } =
    useLoginForm();

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      variant="outlined"
      color="primary"
      sx={{ borderRadius: 'sm' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          p: 2,
        }}
      >
        {/* Drawer Content */}
        <form onSubmit={handleLogin} style={{ flexGrow: 1 }}>
          <Stack
            alignItems="left"
            justifyContent="space-between"
            spacing={2}
            sx={{ maxWidth: '640px', mt: 2 }}
          >
            <FormControl required>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={formState.email}
                onChange={e => handleInputChange('email', e.target.value)}
              />
            </FormControl>
            <FormControl required>
              <FormLabel>{t('password')}</FormLabel>
              <Input
                type="password"
                name="password"
                value={formState.password}
                onChange={e => handleInputChange('password', e.target.value)}
              />
            </FormControl>
            <Stack direction="row" spacing={1}>
              <Button
                variant="plain"
                color="neutral"
                onClick={onClose}
                disabled={isLoading}
              >
                {t('cancel')}
              </Button>
              <Button type="submit" fullWidth disabled={isLoading}>
                {isLoading ? t('loading') : t('signin')}
              </Button>
            </Stack>
          </Stack>
        </form>
        {errorMessage && (
          <Sheet color="danger" variant="soft" sx={{ px: 2, py: 1, borderRadius: 'sm' }}>
            {errorMessage}
          </Sheet>
        )}
      </Box>
    </Drawer>
  );
};

export default LoginDrawerComponent;
