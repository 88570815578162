import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext'; // Adjust the path accordingly
import { useNavigate } from 'react-router-dom';

import { API } from '../../utils/API';
import { useTranslation } from 'react-i18next'; // Text

import SideBarComponent from '../../components/SideBarComponent';
import CustomFormLabel from '../../components/CustomFormLabel';
import LinkSectionComponent from '../../components/LinkSectionComponent';
import FileUploadComponent from '../../components/FileUploadComponent';

import useThemeSwitcher from '../../hooks/useThemeSwitcher';
import useBusinessSettingsForm from './hooks/useBusinessSettingsForm';

import { LinearProgress } from '@mui/joy';

import {
  CssBaseline,
  Box,
  Button,
  Stack,
  FormControl,
  Input,
  Typography,
} from '@mui/joy';

const AdminSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
  const { mode, toggleTheme } = useThemeSwitcher();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { userBusinessId } = useContext(AuthContext);

  const onSuccess = () => {
    // navigate('/admin/events');
  };

  const onError = (error: any) => {
    console.log(error);
  };

  const onLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  const {
    businessInfo,
    formState,
    handleInputChange,
    handleSubmit,
    hasFormChanged,
    isUploadingLogo,
    logoFileUploadState,
    setLogoFile,
    errorMessages,
  } = useBusinessSettingsForm({
    onSuccess,
    onLoading,
  });

  const handleStripeBilling = async () => {
    const response = await API.get(`/api/v1/businesses/admin/${userBusinessId}`, {
      withCredentials: true,
    });
    // console.log&(response)
    if (response.data.stripeCustomerId) {
      // Open Customer Portal
      const response = await API.post(
        `/api/v1/payment/customerPortal/`,
        {},
        { withCredentials: true }
      );
      window.location.href = response.data.url;
    } else {
      // TODO : add a new page for settings including Stripe.
      // Disable stripe settings if no customerId found with a message
      console.log('No StripeCustomerId found');
    }
  };

  // // Update business Information
  // const handleSettingsUpdate = async () => {
  //   try {
  //     setIsUploadingLogo(true);
  //     const logoUploadSuccess = await logoFileUpload.uploadFile(logoFile);
  //     setIsUploadingLogo(false);

  //     if (!logoUploadSuccess) {
  //       setLogoUploadError(logoFileUpload.error?.message || t('fileUploadFailed'));
  //       return;
  //     }

  //     const businessData = { logoPath: logoFile.name };
  //     const response = await axios.patch(
  //       `${baseUrl}/api/v1/businesses/${userBusinessId}`,
  //       businessData,
  //       { withCredentials: true }
  //     );
  //     // TODO: Modify
  //     // console.log(response);
  //     // setBusinessLogo(response.data.logoPath);
  //     setisLogoLoaded(false);
  //     setIsChanged(false);
  //     navigate('/admin/events');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  if (businessInfo) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <SideBarComponent settings={true} />
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            padding: '1em',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
              <h2>{t('settings')}</h2>
            </Box>
            <Box component="form" onSubmit={handleSubmit}>
              <Stack
                className="settings-main"
                justifyContent="left"
                alignItems="left"
                spacing={2}
              >
                <Button
                  variant="outlined"
                  sx={{ maxWidth: '180px' }}
                  onClick={toggleTheme}
                >
                  <span className="material-symbols-outlined">
                    {mode === 'dark' ? 'light_mode' : 'dark_mode'}
                  </span>
                  {mode === 'dark' ? t('actionTurnLightmode') : t('actionTurnDarkmode')}
                </Button>
                <h3>{t('information')}</h3>
                <Box>
                  <CustomFormLabel
                    htmlFor="business-name"
                    label={t('name')}
                    optional={false}
                    flyover={false}
                  />
                  <Input
                    id="business-name"
                    value={formState.name}
                    onChange={e => handleInputChange('name', e.target.value)}
                    sx={{ width: '250px' }}
                  />
                </Box>
                <Box>
                  <CustomFormLabel
                    htmlFor="business-shortname"
                    label={t('businessShortName')}
                    optional={false}
                    flyover={true}
                    flyoverText={t('businessShortNameDescription')}
                  />
                  <Input
                    id="business-shortname"
                    value={formState.shortName}
                    sx={{ width: '250px' }}
                    disabled
                  />
                </Box>
                <FormControl>
                  <CustomFormLabel
                    htmlFor="business-logo"
                    label={t('businessLogo')}
                    optional={false}
                    flyover={true}
                    flyoverText={t('businessShortNameDescription')}
                  />
                  <FileUploadComponent
                    label={t('uploadLogo')}
                    variant="soft"
                    color="primary"
                    allowedTypes={['image/jpeg', 'image/png', 'image/gif']}
                    maxSizeMB={100}
                    onFileSelected={file => {
                      setLogoFile(file);
                    }}
                    initialFileName={
                      businessInfo?.logoPath ? `${businessInfo.shortName}_logo` : ''
                    }
                    isUploading={logoFileUploadState.isUploading}
                    uploadProgress={logoFileUploadState.uploadProgress}
                    uploadErrorMessage={logoFileUploadState.uploadErrorMessage}
                    errorMessages={logoFileUploadState.errorMessages}
                  />
                  {/* {formState.logoPath && !isUploadingLogo && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 2,
                      }}
                    >
                      <img
                        src={`${formState.logoPath}`}
                        alt="Logo"
                        style={{
                          width: '64px',
                          height: '64px',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  )} */}
                </FormControl>
                {errorMessages.length > 0 &&
                  errorMessages.map((error, index) => (
                    <Typography key={index} color="danger">
                      {error}
                    </Typography>
                  ))}
                <Box>
                  <CustomFormLabel
                    htmlFor="business-billing"
                    label={t('billingTitle')}
                    optional={false}
                    flyover={false}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      cursor: businessInfo.stripeCustomerId ? 'pointer' : 'not-allowed',
                      opacity: businessInfo.stripeCustomerId ? 1 : 0.5,
                    }}
                    onClick={
                      businessInfo.stripeCustomerId ? handleStripeBilling : undefined
                    }
                  >
                    <Typography>{t('billingDescription')}</Typography>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: '14px', paddingLeft: 3 }}
                    >
                      open_in_new
                    </span>
                  </Box>

                  {!businessInfo.stripeCustomerId && (
                    <>
                      <Typography color="warning" level="body-xs">
                        {t('billingWarning')}
                      </Typography>
                    </>
                  )}
                </Box>

                {/* Share Business Event list */}
                <h3>{t('share')}</h3>
                <LinkSectionComponent
                  title={t('businessLinkTitle')}
                  link={`${frontendUrl}/business/${businessInfo.shortName}`}
                  imageName={`${businessInfo.shortName}`}
                  QRCodeTitle={`${businessInfo.name}`}
                  businessLogo={businessInfo?.logoPath!}
                />
              </Stack>
              {/* Button at the bottom right */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, gap: 1 }}>
                <Button
                  variant="soft"
                  disabled={!hasFormChanged || isUploadingLogo}
                  type="submit"
                >
                  {t('save')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <SideBarComponent settings={true} />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <LinearProgress />
        </Box>
      </Box>
    );
  }
};

export default AdminSettingsPage;
