// src/hooks/useAudioContextState.js
import React, { useState, useEffect } from 'react';

interface UseAudioContextStateReturn {
  audioContextState: AudioContextState | null;
  isWelcomeModalOpen: boolean;
  handleAudioContextResume: () => Promise<void>;
  setIsWelcomeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useAudioContextState = (
  audioContext: AudioContext | null
): UseAudioContextStateReturn => {
  const [audioContextState, setAudioContextState] = useState<AudioContextState | null>(
    audioContext ? audioContext.state : null
  );

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(
    audioContext ? audioContext.state === 'suspended' : false
  );
  // Monitor audio context state
  useEffect(() => {
    if (audioContext) {
      const handleStateChange = () => {
        setAudioContextState(audioContext.state);
        setIsWelcomeModalOpen(audioContext.state === 'suspended');
      };

      // Add event listener for state changes
      audioContext.addEventListener('statechange', handleStateChange);

      // Set initial state
      handleStateChange();

      // Cleanup
      return () => {
        audioContext.removeEventListener('statechange', handleStateChange);
      };
    } else {
      // Reset state when audioContext changes to null
      setAudioContextState(null);
      setIsWelcomeModalOpen(false);
    }
  }, [audioContext]);

  // Resume audio context
  const handleAudioContextResume = async () => {
    if (audioContext && audioContext.state === 'suspended') {
      await audioContext.resume();
      setIsWelcomeModalOpen(false);
      setAudioContextState(audioContext.state);
    }
  };

  return {
    audioContextState,
    isWelcomeModalOpen,
    handleAudioContextResume,
    setIsWelcomeModalOpen,
  };
};

export default useAudioContextState;
