const getMusicErrorMessages = (t: (key: string) => string) => ({
  UPLOAD_INVALID_FILE_TYPE: t('eventMusicFormatError'),
  UPLOAD_FILE_TOO_BIG: t('eventMusicFileSize'),
  UNKNOWN_ERROR: t('unexpectedUploadError'),
});

const getImageErrorMessages = (t: (key: string) => string) => ({
  UPLOAD_INVALID_FILE_TYPE: t('eventImageFormatError'),
  UPLOAD_FILE_TOO_BIG: t('eventImageFileSize'),
  UNKNOWN_ERROR: t('unexpectedUploadError'),
});

export { getMusicErrorMessages, getImageErrorMessages };
