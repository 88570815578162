// useBusinessSettingsForm.ts

import { useState, useContext, useEffect } from 'react';
import { API } from '../../../utils/API';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../contexts/AuthContext';
import useFileUpload from '../../../hooks/useFileUpload';
import useBusinessInfo from '../../../hooks/useBusinessInfo';
import { cleanFilename } from '../../../utils/fileUtils';

interface UseBusinessSettingsFormProps {
  onSuccess: () => void;
  // onError: (error: any) => void;
  onLoading: (isLoading: boolean) => void;
}

interface FormState {
  name: string;
  shortName: string;
  logoPath: string | null;
}

interface FileUploadState {
  isUploading: boolean;
  uploadProgress: number;
  uploadErrorMessage: string | null;
  errorMessages: Record<string, string>;
}

const useBusinessSettingsForm = ({
  onSuccess,
  onLoading,
}: UseBusinessSettingsFormProps) => {
  const { t } = useTranslation();
  const { userBusinessId } = useContext(AuthContext);

  const [formState, setFormState] = useState<FormState>({
    name: '',
    shortName: '',
    logoPath: '',
  });

  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);
  const [isUploadingLogo, setIsUploadingLogo] = useState<boolean>(false);
  const [logoFile, setLogoFileState] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const { businessInfo } = useBusinessInfo(`admin/${userBusinessId}`);

  const imageErrorMessages = {
    UPLOAD_INVALID_FILE_TYPE: t('eventImageFormatError'),
    UPLOAD_FILE_TOO_BIG: t('eventImageFileSize'),
    UNKNOWN_ERROR: t('unexpectedUploadError'),
  };

  const [logoFileUploadState, setLogoFileUploadState] = useState<FileUploadState>({
    isUploading: false,
    uploadProgress: 0,
    uploadErrorMessage: null,
    errorMessages: imageErrorMessages,
  });

  const logoFileUpload = useFileUpload({
    userBusinessId,
    onProgress: progress => {
      setLogoFileUploadState(prevState => ({
        ...prevState,
        uploadProgress: progress,
        isUploading: true,
      }));
    },
    onError: error => {
      setLogoFileUploadState(prevState => ({
        ...prevState,
        uploadErrorMessage: error.message,
        isUploading: false,
      }));
    },
    onSuccess: filename => {
      setLogoFileUploadState(prevState => ({
        ...prevState,
        isUploading: false,
        uploadProgress: 0,
        uploadErrorMessage: null,
        errorMessages: imageErrorMessages,
      }));
    },
  });

  const setLogoFile = (file: File | null) => {
    const newFile = cleanFilename(file);
    if (newFile) {
      setLogoFileState(newFile);
      // setHasFormChanged(true);
      handleInputChange('logoPath', newFile.name);
    }
  };

  // Initialize form when editing an existing event
  useEffect(() => {
    if (businessInfo) {
      setFormState({
        name: businessInfo.name,
        shortName: businessInfo.shortName,
        logoPath: businessInfo.logoPath,
      });
    }
  }, [businessInfo]);

  // Handle input changes in the form (Settings changes)
  const handleInputChange = (field: keyof FormState, value: any) => {
    console.log(field);
    console.log(value);
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }));
    setHasFormChanged(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onLoading(true);
    try {
      // If logo file has changed, upload it
      if (logoFile) {
        setIsUploadingLogo(true);
        const logoUploadSuccess = await logoFileUpload.uploadFile(logoFile);
        setIsUploadingLogo(false);

        if (!logoUploadSuccess) {
          setErrorMessages([logoFileUpload.error?.message || t('fileUploadFailed')]);
          onLoading(false);
          return;
        }
        console.log('updating formState');
        // Update logoPath in formState
        // handleInputChange('logoPath', logoFile.name);
      }

      // Prepare data to be updated
      const updatedData: Partial<FormState> = {};

      //   if (formState.name !== businessInfo?.name) {
      //     updatedData.name = formState.name;
      //   }
      console.log(formState.logoPath);
      console.log(businessInfo?.logoPath);

      if (formState.logoPath !== businessInfo?.logoPath) {
        updatedData.logoPath = formState.logoPath;
      }

      if (Object.keys(updatedData).length === 0) {
        onLoading(false);
        onSuccess();
        return;
      }
      // console.log(updatedData);
      // console.log('patching');
      await API.patch(`/api/v1/businesses/${userBusinessId}`, updatedData, {
        withCredentials: true,
      });
      console.log('done');
      setHasFormChanged(false);
      onSuccess();
    } catch (error) {
      console.error('Failed to update business info:', error);
      //TODO : replace error message
      setErrorMessages([t('unexpectedError')]);
    } finally {
      onLoading(false);
    }
  };

  return {
    businessInfo,
    formState,
    handleInputChange,
    handleSubmit,
    hasFormChanged,
    isUploadingLogo,
    logoFileUploadState,
    setLogoFile,
    errorMessages,
  };
};

export default useBusinessSettingsForm;
