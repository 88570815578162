import { useState, useEffect } from 'react';
import axios from 'axios';

import { UserInfo } from '../../../types/userTypes';
import { BusinessInfo } from '../../../types/businessTypes';
import { API } from '../../../utils/API';

export interface UseUserListReturn {
  users: UserInfo[];
  businesses: BusinessInfo[];
  loading: boolean;
  error: any;
}

export default function useUserList(): UseUserListReturn {
  const [users, setUsers] = useState<UserInfo[]>([]);
  const [businesses, setBusinesses] = useState([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchAllUsers = async () => {
    try {
      setLoading(true);
      console.log('fetching users!');
      // Get all Users
      const usersResponse = await API.get(`/api/v1/users/`, {
        withCredentials: true,
      });

      setUsers(usersResponse.data);
      // console.log(`usersResponse:`, usersResponse);
      // console.log(usersResponse);
      // get all Businesses
      const businessResponse = await API.get(`/api/v1/businesses/`, {
        withCredentials: true,
      });
      //   console.log(businessResponse.data);
      setBusinesses(businessResponse.data);
    } catch (error) {
      console.error('Failed to fetch all users:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Get User Information
  useEffect(() => {
    fetchAllUsers();
  }, [API]);

  return { users, businesses, loading, error };
}
