import React, { useState, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next'; // Text

import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/joy/styles';
import { Typography, Box, Stack, Button } from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';

import BrandFooterComponent from '../components/BrandFooterComponent';
import useThemeSwitcher from '../hooks/useThemeSwitcher';

export default function EventPaymentSuccessPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mode, toggleTheme } = useThemeSwitcher();
  const messages = t('successfulPayment', { returnObjects: true }) as string[];

  const handleRedirectButton = async () => {
    navigate('/admin/events/');
  };

  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '100dvh',
          justifyContent: 'space-between',
          maxWidth: '480px',
          width: 'auto',
          margin: 'auto',
          px: 2,
        }}
      >
        <Box
          component="header"
          sx={{
            py: 3,
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'space-between',
          }}
        ></Box>
        <Box
          component="main"
          sx={{
            p: 2,
            borderRadius: 'sm',
            border: '1px solid black',
            backgroundColor: theme.palette.primary.outlinedBg,
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: 'md',
          }}
        >
          <Stack spacing={2} sx={{ width: '100%' }}>
            {/* Image */}
            <Box
              component="img"
              src="/successfulPayment.png"
              alt="Successful payment"
              sx={{
                maxHeight: '30vh',
                maxWidth: '100%',
                objectFit: 'contain',
                width: 'auto',
                height: 'auto',
              }}
            />
            <Typography sx={{ textAlign: 'left' }}>
              {messages.map((line: string, index: number) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="outlined" onClick={handleRedirectButton}>
                {t('redirect')}
              </Button>
            </Stack>
          </Stack>
        </Box>
        {/* Footer */}
        <Box component="footer" sx={{ py: 3 }}>
          <BrandFooterComponent mode={mode} showDate={true} />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
