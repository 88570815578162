import { useState, useContext } from 'react';
import { API } from '../../../utils/API';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../contexts/AuthContext';

interface UseBusinessFormProps {
  onSuccess: () => void;
}

interface FormState {
  businessName: string;
  businessShortName: string;
}

const useBusinessForm = ({ onSuccess }: UseBusinessFormProps) => {
  const { t } = useTranslation();
  const { userEmail, setUserBusinessId } = useContext(AuthContext);

  const [formState, setFormState] = useState<FormState>({
    businessName: '',
    businessShortName: '',
  });

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businessShortNameEdited, setBusinessShortNameEdited] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({});

  const handleInputChange = (field: keyof FormState, value: string) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }));

    if (field === 'businessName') {
      handleBusinessNameChange(value);
    } else if (field === 'businessShortName') {
      handleBusinessShortNameChange(value);
    }
  };

  const handleBusinessNameChange = (newBusinessName: string) => {
    let formattedShortName = formState.businessShortName;

    // Reset edited if both businessShortName & businessName are empty
    if (businessShortNameEdited && !formState.businessShortName) {
      setBusinessShortNameEdited(false);
    }

    // Automatically generate a short name only if it has not been manually edited
    if (!businessShortNameEdited) {
      formattedShortName = newBusinessName
        .toLowerCase()
        .replace(/[\s\W-]+/g, '-')
        .replace(/^-+|-+$/g, '');
      setFormState(prevState => ({
        ...prevState,
        businessShortName: formattedShortName,
      }));
    }
    checkFormValidity(
      newBusinessName,
      businessShortNameEdited ? formState.businessShortName : formattedShortName
    );
  };

  const handleBusinessShortNameChange = (newShortName: string) => {
    if (newShortName) {
      setBusinessShortNameEdited(true);
    } else if (!newShortName && !formState.businessName) {
      setBusinessShortNameEdited(false);
    }

    const hasSpecialChars = /[^a-zA-Z0-9-]/.test(newShortName);
    setErrorMessages({
      ...errorMessages,
      businessShortName: hasSpecialChars ? t('businessShortNameSpecialCharError') : '',
    });

    checkFormValidity(formState.businessName, newShortName);
  };

  // Check form validity
  const checkFormValidity = (name: string, shortName: string) => {
    const nameValid = name.trim() !== '';
    const shortNameValid = shortName.trim() !== '' && !/[^a-zA-Z0-9-]/.test(shortName);
    setIsFormValid(nameValid && shortNameValid);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent form submission from reloading the page

    // Create Business & attach businessId to user
    setIsLoading(true);
    const businessData = {
      name: formState.businessName,
      shortName: formState.businessShortName,
      userEmail: userEmail,
    };
    try {
      const businessCreated = await API.post(`/api/v1/businesses`, businessData, {
        withCredentials: true,
      });
      setUserBusinessId(businessCreated.data.id);
      onSuccess();
    } catch (error: any) {
      // API error
      if (error.response?.data?.error === 'BUSINESS_ALREADY_EXISTS') {
        setErrorMessages(prev => ({ ...prev, error: t('businessExistError') }));
      } else {
        // Unexpected error
        setErrorMessages(prev => ({ ...prev, error: t('businessError') }));
      }
    } finally {
      setIsLoading(false);
    }
  };
  return {
    formState,
    handleInputChange,
    handleSubmit,
    isLoading,
    errorMessages,
    isFormValid,
  };
};

export default useBusinessForm;
