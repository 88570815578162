// ProtectedRoute.js
import React, { useContext, ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import LinearProgress from '@mui/joy/LinearProgress';

interface ProtectedRouteProps {
  children: ReactNode;
  requiredRole?: string;
}

export const ProtectedRoute = ({ children, requiredRole }: ProtectedRouteProps) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();

  if (!authContext) {
    // Handle the case where AuthContext is undefined
    return <Navigate to="/signin" replace />;
  }

  const { isAuthenticated, userRole, isLoading } = authContext;

  if (isLoading) {
    return <LinearProgress color="neutral" variant="outlined" />;
  }

  if (
    !isAuthenticated ||
    (requiredRole && userRole && userRole !== requiredRole && userRole !== 'super-admin')
  ) {
    // console.log(`isAuthenticated is ${isAuthenticated} \n
    // userRole is ${userRole}
    // requiredRole is ${requiredRole}
    // User is not authenticated or has not the sufficient rights
    // `)

    // console.log('User is not authenticated or has not the sufficient rights')
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
};
