// useUserForm.ts

import { useState } from 'react';
import { API } from '../../../utils/API';
import { useTranslation } from 'react-i18next';

// import { UserInfo } from '../../../types/userTypes';

interface UseUserFormProps {
  //   isEditing: boolean;
  //   userData?: UserInfo;
  onSuccess: () => void;
  onError: (error: any) => void;
  onLoading: (isLoading: boolean) => void;
  //   onFormChange: () => void;
}

interface FormState {
  email: string;
  password: string;
  businessId: string;
}

interface ErrorsState {
  [key: string]: string[];
  // email?: string[];
  // password?: string[];
  // businessId?: string[];
  // general?: string[];
}
const useUserForm = ({
  //   isEditing,
  //   eventData,
  onSuccess,
  onError,
  onLoading,
  //   onFormChange,
}: UseUserFormProps) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<FormState>({
    email: '',
    password: '',
    businessId: '',
  });

  const [errors, setErrors] = useState<ErrorsState>({
    email: [],
    password: [],
    businessId: [],
    general: [],
  });

  // Validation functions
  const validateEmail = (email: string): string[] => {
    const errors: string[] = [];
    if (!email) {
      errors.push(t('emailMissingError'));
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      errors.push(t('emailInvalidError'));
    }
    return errors;
  };

  const validatePassword = (password: string): string[] => {
    const errors: string[] = [];
    if (!password) {
      errors.push(t('passwordMissingError'));
    }
    if (password.length < 8 || password.length > 15) {
      errors.push(t('passwordLengthError'));
    }
    if (!/\d/.test(password)) {
      errors.push(t('passwordNumberMissingError'));
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push(t('passwordSpecialCharacterMissingError'));
    }
    return errors;
  };

  const validateBusinessId = (businessId: string): string | undefined => {
    if (!businessId) {
      return t('businessMissing');
    }
    return undefined;
  };

  const handleInputChange = (field: keyof FormState, value: any) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }));

    // Validate the field and update errors
    let errorsArray: string[] = [];
    if (field === 'email') {
      errorsArray = validateEmail(value);
    } else if (field === 'password') {
      errorsArray = validatePassword(value);
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [field]: errorsArray,
    }));
  };

  const resetForm = () => {
    setFormState({
      email: '',
      password: '',
      businessId: '',
    });
    setErrors({});
  };

  // Handle business select with validation
  const handleBusinessSelect = (businessId: string | null) => {
    setFormState(prevState => ({
      ...prevState,
      businessId: businessId ?? '',
    }));

    const error = validateBusinessId(businessId ?? '');
    setErrors(prevErrors => ({
      ...prevErrors,
      businessId: error ? [error] : [],
    }));
  };

  const createUser = async () => {
    onLoading(true);

    try {
      const newUserData = {
        ...formState,
      };
      console.log(newUserData);
      await API.post(`/api/v1/auth/signup`, newUserData);
      resetForm();
      onSuccess();
    } catch (error: any) {
      // Handle backend errors here
      if (error.response && error.response.data && error.response.data.details.errors) {
        const errorDetails = error.response.data.details.errors;
        const newErrors: ErrorsState = {};
        errorDetails.forEach((err: any) => {
          if (err.param && err.msg) {
            if (!newErrors[err.param]) {
              newErrors[err.param] = [];
            }
            newErrors[err.param]?.push(err.msg);
          }
        });
        setErrors(newErrors);
      } else {
        setErrors({ general: [t('unexpectedError')] });
      }
      onError(error);
    } finally {
      onLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // if (isEditing) {
    //   await updateUser();
    // } else {
    await createUser();
    // }
  };

  return {
    formState,
    handleInputChange,
    handleSubmit,
    handleBusinessSelect,
    errors,
  };
};

export default useUserForm;
