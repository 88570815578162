import React, { useEffect, useState } from 'react';

import { Drawer, Stack, Typography, Box, Button, Sheet, Chip } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import TimePicker from '../../../components/TimePicker';
import { formatSeconds, formatDateWHours } from '../../../utils/formatUtils';
// import { Chip } from '@mui/material';

interface ChangeScheduledTimeDrawerComponentProps {
  open: boolean;
  handleClose: () => void;
  updateEventLaunchedTime: (time: Date, isArmed: boolean) => void;
  scheduledTime: Date;
  ntpOffset: number;
  isTest: boolean;
  isArmed: boolean;
}

const SCHEDULED_TIME_30_SECS = 30;
const SCHEDULED_TIME_5_MIN = 300;

const ChangeScheduledTimeDrawerComponent: React.FC<
  ChangeScheduledTimeDrawerComponentProps
> = ({
  open,
  handleClose,
  updateEventLaunchedTime,
  scheduledTime,
  ntpOffset,
  isTest,
  isArmed,
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [selectedDateText, setSelectedDateText] = useState<string>('');

  // Reset when opening the drawer
  useEffect(() => {
    // Initialize Selected Date in timepicker
    // setSelectedDate(new Date());
    // setIsChanged(false);
    // setSelectedDateText('');
    if (scheduledTime) {
      setSelectedDate(new Date());
      setIsChanged(false);
      setSelectedDateText('');
    }
  }, [scheduledTime]);

  const handleSubmit = () => {
    console.log(selectedDate);
    if (selectedDate) {
      updateEventLaunchedTime(selectedDate, isArmed);
    } else {
      console.error('Invalid date selected');
    }
  };

  // Button set the time to ntpTime + 15 seconds
  const handleTimewButton = (delay: number) => {
    const newScheduledTime = new Date(
      Math.round(new Date(Date.now() + ntpOffset).getTime() / 1000 + delay) * 1000
    );
    console.log(newScheduledTime);
    setSelectedDate(newScheduledTime);
    // updateEvent(newScheduledTime);
    setIsChanged(true);
    setSelectedDateText(formatSeconds(delay));
  };

  const handleTimewPicker = (newTime: Date) => {
    setSelectedDate(newTime); // Update local state
    setIsChanged(newTime !== new Date(scheduledTime));
    setSelectedDateText(formatDateWHours(newTime.toISOString()));
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      variant="outlined"
      color="primary"
    >
      <Stack
        direction="column"
        alignItems="left"
        spacing={2}
        mx="auto"
        p={2}
        sx={{ maxWidth: '1000px' }}
      >
        <Typography level="h4">
          {isTest ? t('scheduleTest') : t('scheduleMusic')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant="soft"
            onClick={() => {
              handleTimewButton(SCHEDULED_TIME_30_SECS); /* toggleDrawer(false)();*/
            }}
          >
            30 sec
          </Button>
          <Button
            variant="soft"
            onClick={() => {
              handleTimewButton(SCHEDULED_TIME_5_MIN); /*toggleDrawer(false)();*/
            }}
          >
            5 min
          </Button>
        </Box>
        <Typography level="h3">{t('or')}</Typography>
        <Box sx={{ flexGrow: 1 }}>
          <TimePicker onTimeSet={handleTimewPicker} />
        </Box>
        {selectedDateText && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography level="body-md">{t('scheduleSelected')}</Typography>

            <Chip variant="soft" color="primary" sx={{ ml: 1 }}>
              <Typography level="body-md">{selectedDateText}</Typography>
            </Chip>
          </Box>
        )}
        {/* Drawer Buttons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'right',
            width: '100%',
            maxWidth: '640px',
            mt: 2,
          }}
        >
          <Button
            variant="plain"
            color="neutral"
            onClick={() => {
              setSelectedDate(scheduledTime);
              setIsChanged(false);
              handleClose();
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="outlined"
            disabled={!isChanged}
            onClick={() => {
              handleSubmit();
              handleClose();
            }}
          >
            {t('changeScheduledDate')}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default ChangeScheduledTimeDrawerComponent;
