import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { API } from '../utils/API';
import { WebSocketContext } from '../contexts/WebSocketContext';
import { EventInfo } from '../types/eventTypes';

export interface UseEventInfoReturn {
  eventInfo: EventInfo | null;
  scheduledTime: Date | null;
  isArmed: boolean;
  testTime: Date | null;
  eventError: any;
  isEventPassed: boolean;
  isEventLoading: boolean;
}
export default function useEventInfo(eventIdentifier: string): UseEventInfoReturn {
  const { t } = useTranslation();
  const { socket } = useContext(WebSocketContext);

  const [eventInfo, setEventInfo] = useState<EventInfo | null>(null);
  const [scheduledTime, setScheduledTime] = useState<Date | null>(null);
  const [testTime, setTestTime] = useState<Date | null>(null);
  const [isArmed, setIsArmed] = useState<boolean>(false);
  const [isEventPassed, setIsEventPassed] = useState<boolean>(true);

  // Use React Query to fetch event information
  const {
    data,
    isLoading: isEventLoading,
    isError,
    error: eventError,
  } = useQuery<EventInfo>({
    queryKey: ['eventInfo', eventIdentifier],
    queryFn: async () => {
      const response = await API.get<EventInfo>(`/api/v1/events/${eventIdentifier}`);
      return response.data;
    },
  });

  // Handle successful data fetch
  useEffect(() => {
    console.log(data);
    if (data) {
      if (!data.isPassed) {
        setEventInfo(data);
        setScheduledTime(data.scheduledTime ? new Date(data.scheduledTime) : null);
        setTestTime(data.testTime ? new Date(data.testTime) : null);
        setIsArmed(data.isArmed);
        setIsEventPassed(false);
      } else {
        console.log('event is Passed!');
        const fallbackEventData = {
          id: data.id,
          imgPath: data.imgPath,
          name: data.name,
          description: t('eventFinishedDescription'),
          isPassed: data.isPassed,
          isArmed: false,
        };
        setEventInfo(fallbackEventData);
        setIsEventPassed(true);
      }
    }
  }, [data]);

  // Handle errors
  useEffect(() => {
    if (isError) {
      // console.error('Failed to fetch event information:', error);
      const fallbackEventData: EventInfo = {
        imgPath: '/fireworks_404_events.svg',
        name: 'Oh Oh...',
        description: t('eventNotFoundDescription'),
        isArmed: false,
      };
      setEventInfo(fallbackEventData);
      setIsEventPassed(true);
    }
  }, [isError, eventError]);

  // Set-up socket for real-time updates
  useEffect(() => {
    if (!socket || !eventInfo?.id) {
      return;
    }

    const handleEventUpdated = (updatedEvent: EventInfo) => {
      console.log('Websocket Event Updated');
      if (updatedEvent.id === eventInfo.id) {
        // Check if scheduledTime was part of the update
        if (updatedEvent.scheduledTime) {
          // console.log(`WebSocket updated: ${updatedEvent.scheduledTime}`);
          setScheduledTime(new Date(updatedEvent.scheduledTime));
        }
        // Check if testTime was part of the update
        if (updatedEvent.testTime) {
          // console.log(`WebSocket updated: ${updatedEvent.testTime}`);
          setTestTime(new Date(updatedEvent.testTime));
        }
        // Check if isArmed was part of the update
        // if (updatedEvent.isArmed) {
        // console.log(`WebSocket updated: ${updatedEvent.isArmed}`);
        setIsArmed(updatedEvent.isArmed);
      }
    };

    socket.on('event-updated', handleEventUpdated);

    return () => {
      if (socket) {
        socket.off('event-updated', handleEventUpdated);
      }
    };
  }, [socket, eventInfo?.id]);

  return {
    eventInfo,
    scheduledTime,
    testTime,
    isArmed,
    eventError,
    isEventPassed,
    isEventLoading,
  }; //isEventPassed
}
