import { FormControl, FormLabel, Select, Option, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessInfo } from '../../../types/businessTypes';

interface BusinessSelectComponentProps {
  businesses: BusinessInfo[];
  selectedBusinessId: string;
  onBusinessSelect: (businessId: string | null) => void;
  error?: boolean;
}

const BusinessSelectComponent: React.FC<BusinessSelectComponentProps> = ({
  businesses,
  selectedBusinessId,
  onBusinessSelect,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel htmlFor="user-business">{t('business')}</FormLabel>
      <Select
        id="user-business"
        value={selectedBusinessId}
        // onChange={(e) => setSelectedBusiness(e)}
        onChange={(e, newValue) => onBusinessSelect(newValue)}
        // placeholder={t('selectBusiness')}
      >
        {businesses.map(business => (
          <Option key={business.id} value={business.id}>
            {business.name}
          </Option>
        ))}
      </Select>
    </FormControl>
  );
};

export default BusinessSelectComponent;
