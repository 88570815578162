// src/hooks/useTestSound.ts

import { useState } from 'react';
import { API } from '../utils/API';

const useTestSound = () => {
  const [isTestSoundModalOpen, setIsTestSoundModalOpen] = useState<boolean>(false);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [audioBufferSourceNode, setAudioBufferSourceNode] =
    useState<AudioBufferSourceNode | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isTestPlaying, setIsTestPlaying] = useState<boolean>(false); // New state to track if sound is playing

  const testSound = async () => {
    try {
      if (audioContext) {
        audioContext.close(); // Close any existing audio context to prevent overlap
      }
      const context = new (window.AudioContext || (window as any).webkitAudioContext)();
      setAudioContext(context);

      const response = await API.get('api/v1/file/ressources/test_sound'); // Get test_sound S3 bucket
      const s3response = await fetch(response.data.ressourcePath);

      const arrayBuffer = await s3response.arrayBuffer();
      const audioBuffer = await context.decodeAudioData(arrayBuffer);

      const source = context.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(context.destination);
      setIsTestPlaying(true);
      source.start(0);
      source.onended = () => {
        setIsTestPlaying(false); // Set to false when the sound finishes
      };
      setAudioBufferSourceNode(source);

      setIsTestSoundModalOpen(true);
    } catch (err: any) {
      console.error('Error playing test sound:', err);
      setError('Failed to play test sound.');
      setIsTestPlaying(false);
    }
  };

  const closeTestSoundModal = () => {
    if (audioBufferSourceNode) {
      audioBufferSourceNode.stop();
      audioBufferSourceNode.disconnect();
    }
    if (audioContext) {
      audioContext.close();
    }
    setAudioContext(null);
    setAudioBufferSourceNode(null);
    setIsTestSoundModalOpen(false);
    setIsTestPlaying(false);
  };

  return {
    isTestSoundModalOpen,
    testSound,
    closeTestSoundModal,
    isTestPlaying,
    error,
  };
};

export default useTestSound;
