import React, { useContext, useEffect } from 'react';

import { AuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/joy/styles';
import {
  Button,
  Input,
  Stack,
  FormControl,
  FormLabel,
  Typography,
  Box,
  Sheet,
  CircularProgress,
  CssVarsProvider,
  GlobalStyles,
} from '@mui/joy';

import CustomFormLabel from '../../components/CustomFormLabel';
import BrandFooterComponent from '../../components/BrandFooterComponent';

import useThemeSwitcher from '../../hooks/useThemeSwitcher';

import useBusinessForm from './hooks/useBusinessForm';

export default function OnBoardingPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { userBusinessId, userRole } = useContext(AuthContext);

  const { mode, toggleTheme } = useThemeSwitcher();

  useEffect(() => {
    // If the user already has a businessId, redirect them to /admin/events
    if (userBusinessId || userRole === 'super-admin') {
      navigate('/admin/events/');
    }
  });

  const {
    formState,
    handleInputChange,
    handleSubmit,
    isLoading,
    errorMessages,
    isFormValid,
  } = useBusinessForm({
    onSuccess: () => {
      navigate('/admin/events/');
    },
  });

  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px',
            '--Cover-width': '50vw',
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s',
          },
        }}
      />
      <Box
        sx={{
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          ></Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
            }}
          >
            <Typography level="h4" sx={{ textAlign: 'left' }}>
              {t('welcomeBusinessOnBoarding')}
            </Typography>
            {/* Form */}
            <Box
              component="form"
              sx={{
                p: 2,
                borderRadius: 'sm',
                border: '1px solid black',
                backgroundColor: theme.palette.primary.outlinedBg,
                borderColor: 'divider',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: 'md',
              }}
              onSubmit={handleSubmit}
            >
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Typography level="h4" sx={{ textAlign: 'left' }}>
                  {t('createBusiness')}
                </Typography>
                <FormControl>
                  <FormLabel htmlFor="business-name">{t('name')}</FormLabel>
                  <Input
                    id="business-name"
                    value={formState.businessName}
                    onChange={e => handleInputChange('businessName', e.target.value)}
                    variant="outlined"
                    required
                  />
                </FormControl>
                <FormControl>
                  <CustomFormLabel
                    htmlFor="business-shortname"
                    label={t('businessShortName')}
                    optional={false}
                    flyover={true}
                    flyoverText={'businessShortNameDescription'}
                  />
                  <Input
                    id="businessShort-name"
                    value={formState.businessShortName}
                    onChange={e => handleInputChange('businessShortName', e.target.value)}
                    variant="outlined"
                    required
                    error={!!errorMessages.businessShortName}
                  />{' '}
                  {/* Display the error message if it exists */}
                  {errorMessages.businessShortName && (
                    <Typography color="danger">
                      {errorMessages.businessShortName}
                    </Typography>
                  )}
                </FormControl>
                {errorMessages && errorMessages.error && (
                  <Sheet
                    color="danger"
                    variant="soft"
                    sx={{ px: 2, py: 1, borderRadius: 'sm' }}
                  >
                    <Typography color="danger">{errorMessages.error}</Typography>
                  </Sheet>
                )}
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={!isFormValid || isLoading}
                    startDecorator={
                      isLoading ? (
                        <CircularProgress
                          size="sm"
                          sx={{ bgcolor: 'background.surface' }}
                        />
                      ) : null
                    }
                  >
                    {t('create')}
                  </Button>
                </Stack>
                <Typography level="body-xs" sx={{ fontSize: '0.75rem', mb: '0.5rem' }}>
                  {t('accountCreationTermsConditionsPart1')}
                  <a
                    href="https://orbiner.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('accountCreationTermsConditionsTerms')}
                  </a>
                  {t('accountCreationTermsConditionsAnd')}
                  <a
                    href="https://orbiner.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('accountCreationTermsConditionsPrivacy')}
                  </a>
                </Typography>
              </Stack>
            </Box>
          </Box>

          {/* Footer */}
          {/* Footer */}
          <Box component="footer" sx={{ py: 3 }}>
            <BrandFooterComponent mode={mode} showDate={true} />
          </Box>
        </Box>
      </Box>
      {/* Image */}
      <Box
        sx={{
          m: 5,
          height: '45vh',
          position: 'fixed',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/signin_illustration.png)',
        }}
      />
    </CssVarsProvider>
  );
}
