// src/hooks/useGoogleSignIn.ts

import { BASE_URL } from '../../../utils/API';

const useGoogleSignIn = () => {
  const handleGoogleSignIn = () => {
    // Redirect to Google OAuth route
    window.location.href = `${BASE_URL}/api/v1/auth/google`;
  };

  return {
    handleGoogleSignIn,
  };
};

export default useGoogleSignIn;
