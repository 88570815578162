// src/views/adminevents/hooks/useEventDeletion.ts

import { useState } from 'react';
import { API } from '../../../utils/API';
import { EventInfo } from '../../../types/eventTypes';

interface UseEventDeletionReturn {
  confirmationModalOpen: boolean;
  eventToDelete: EventInfo | null;
  initiateDeleteEvent: (event: EventInfo) => void;
  confirmDeleteEvent: () => Promise<void>;
  cancelDeleteEvent: () => void;
}

export default function useEventDeletion(): UseEventDeletionReturn {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [eventToDelete, setEventToDelete] = useState<EventInfo | null>(null);

  const initiateDeleteEvent = (event: EventInfo) => {
    setEventToDelete(event);
    setConfirmationModalOpen(true);
  };

  const confirmDeleteEvent = async () => {
    if (eventToDelete) {
      try {
        await API.delete(`/api/v1/events/${eventToDelete.id}`, {
          withCredentials: true,
        });
        setConfirmationModalOpen(false);
        setEventToDelete(null);
      } catch (error) {
        console.error('Error deleting event:', error);
      }
    }
  };

  const cancelDeleteEvent = () => {
    setConfirmationModalOpen(false);
    setEventToDelete(null);
  };

  return {
    confirmationModalOpen,
    eventToDelete,
    initiateDeleteEvent,
    confirmDeleteEvent,
    cancelDeleteEvent,
  };
}
