import { useState, useEffect } from 'react';
import axios from 'axios';
import { API } from '../utils/API';
import { BusinessInfo } from '../types/businessTypes';

interface UseBusinessListReturn {
  businesses: BusinessInfo[];
  loading: boolean;
  error: Error | null;
  handleDeleteBusiness: (selectedBusinessId: string) => void;
}

export default function useBusinessList(): UseBusinessListReturn {
  const [businesses, setBusinesses] = useState<BusinessInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchAllBusinesses = async () => {
    try {
      // get all Businesses
      const businessResponse = await API.get<BusinessInfo[]>(`/api/v1/businesses/`, {
        withCredentials: true,
      });
      // console.log(businessResponse.data.data)
      setBusinesses(businessResponse.data);
    } catch (error: any) {
      console.error('Failed to fetch all businesses:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllBusinesses();
  });

  const handleDeleteBusiness = async (selectedBusinessId: number | string) => {
    try {
      await API.delete(`/api/v1/businesses/${selectedBusinessId}`, {
        withCredentials: true,
      });
      setBusinesses(businesses.filter(business => business.id !== selectedBusinessId));
    } catch (error: any) {
      console.error('Error deleting business:', error);
      setError(error);
    }
  };

  return {
    businesses,
    loading,
    error,
    handleDeleteBusiness,
  };
}
