import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL || '';

export const API = axios.create({
  baseURL: BASE_URL,
});

//TODO handle Api Error
// export const handleApiError = async (error:any) => {
//   try {
//     const errorMessage =
//       error.response?.data?.message || "An unexpected error occurred.";
//     const data = null;
//     return { error: errorMessage, data };
//   } catch (err) {
//     throw new Error("An unexpected error occurred.");
//   }
// };

// Helper function to convert VAPID key
export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
