import React from 'react';
import { Box, Typography, Sheet } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface TimecodeInfoComponentProps {
  isTimecode: boolean;
  timecodeChannel: boolean;
}

const TimecodeInfoComponent: React.FC<TimecodeInfoComponentProps> = ({
  isTimecode,
  timecodeChannel,
}) => {
  const { t } = useTranslation();

  if (!isTimecode) {
    return null;
  }

  return (
    <Sheet
      color="warning"
      variant="soft"
      sx={{ p: 2, borderRadius: 'md', width: '100%' }}
    >
      <Typography>{t('pyroTimecode')}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {timecodeChannel ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
              <span className="material-symbols-outlined">speaker</span>
              <Typography>{`L : ${t('music')}`}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '900',
                minWidth: 0,
              }}
            >
              <span className="material-symbols-outlined">speaker</span>
              <Typography>{`R : ${t('timecode')}`}</Typography>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '900',
                minWidth: 0,
              }}
            >
              <span className="material-symbols-outlined">speaker</span>
              <Typography>{`L : ${t('timeCode')}`}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
              <span className="material-symbols-outlined">speaker</span>
              <Typography>{`R : ${t('music')}`}</Typography>
            </Box>
          </>
        )}
      </Box>
    </Sheet>
  );
};

export default TimecodeInfoComponent;
