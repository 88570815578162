// components/WelcomeModalComponent.js
import React, { useState } from 'react';
import {
  Modal,
  ModalDialog,
  DialogContent,
  Button,
  Box,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from '@mui/joy';
import Typography from '@mui/joy/Typography';

import { useTheme } from '@mui/joy/styles';
import { useTranslation } from 'react-i18next';

interface TestSoundModalComponentProps {
  open: boolean;
  close: () => void;
  replaySound: () => Promise<void>;
  isTestPlaying: boolean; // test sound is playing
}

const TestSoundModalComponent: React.FC<TestSoundModalComponentProps> = ({
  open,
  close,
  replaySound,
  isTestPlaying,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const testAppleSoundExplanation = t('testAppleSoundExplanation', {
    returnObjects: true,
  }) as string[];
  const testAndroidSoundExplanation = t('testAndroidSoundExplanation', {
    returnObjects: true,
  }) as string[];

  // State to track if the sound is playing
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  // Function to handle replaying sound
  const handleReplay = async () => {
    setIsPlaying(true); // Show text when replaying starts
    await replaySound();
    setIsPlaying(false); // Hide text after replay finishes
  };

  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="test-sound-modal-title"
        aria-describedby="test-sound-modal-description"
        sx={{ backdropFilter: 'blur(10px)' }}
      >
        <ModalDialog
          variant="outlined"
          color="primary"
          sx={{
            width: '90vw',
            maxWidth: '500px',
            // width: 'auto',
            mx: 'auto',
            overflow: 'hidden',
            p: 0,
          }}
        >
          <DialogContent>
            {/* Banner with soundplaying text + image if music is playing or button to replay sound*/}
            <Box
              sx={{
                pt: 6,
                position: 'sticky', // Change to sticky to make it fixed during scroll
                top: 0,
                backgroundColor: theme.palette.background.body,
                backgroundImage: 'url("/musicnotes_illustration.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mx: 'auto',
                width: '100%',
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
              }}
            >
              {isTestPlaying ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                  }}
                >
                  <img
                    src="/music_playing_light.gif"
                    alt="music notes"
                    style={{
                      display: 'block',
                      margin: 0,
                      width: 40,
                      height: 40,
                      objectFit: 'cover',
                      padding: 0,
                    }}
                  />
                  <Typography level="h2" id="testsound-modal-soundtest">
                    {t('testSoundPlaying')}
                  </Typography>
                  {/* <img
                    src="/music_playing_light.gif"
                    alt="music notes"
                    style={{
                      display: 'block',
                      margin: 0,
                      width: 40,
                      height: 40,
                      objectFit: 'cover',
                      padding: 0,
                    }}
                  /> */}
                </Box>
              ) : (
                <Button variant="outlined" size="lg" onClick={handleReplay}>
                  <span className="material-symbols-outlined">replay</span>
                  <Typography>{t('replaySound')}</Typography>
                </Button>
              )}
            </Box>
            <Box
              sx={{
                // p: '1.25em',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
              }}
            >
              {/* Modal Component */}
              <Box sx={{ p: 2 }}>
                <Typography level="h1" id="testsound-modal-title">
                  {t('testSoundModalTitle')}
                </Typography>

                <Tabs
                  aria-label="phone-mode"
                  defaultValue={'apple'}
                  sx={{
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                  }}
                >
                  <TabList variant="outlined" color="primary" sx={{ width: '100%' }}>
                    <Tab sx={{ width: '100%' }} value="android">
                      <img
                        src="/android_logo.svg"
                        alt="Android Logo"
                        style={{
                          height: 24,
                          width: 24,
                          display: 'block',
                          marginRight: '1em',
                          padding: 0,
                        }}
                      />
                      {t('android')}
                    </Tab>
                    <Tab
                      // color='primary'
                      // variant='soft'
                      sx={{ width: '100%' }}
                      value="apple"
                    >
                      <img
                        src="/apple_logo.png"
                        alt="Apple Logo"
                        style={{
                          height: 24,
                          width: 24,
                          display: 'block',
                          marginRight: '1em',
                          padding: 0,
                        }}
                      />
                      {t('apple')}
                    </Tab>
                  </TabList>

                  <TabPanel value={'apple'}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                      }}
                    >
                      <img
                        src="/apple_logo.png"
                        alt="Apple Logo"
                        style={{
                          height: 24,
                          width: 24,
                          display: 'block',
                          marginRight: '1em',
                          padding: 0,
                        }}
                      />
                      <Typography level="h3" id="testsound-modal-title">
                        {t('apple')}
                      </Typography>
                    </Box>
                    <Typography sx={{ textAlign: 'left' }}>
                      {testAppleSoundExplanation.map((line: string, index: number) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </TabPanel>
                  <TabPanel value={'android'}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                        mt: 1,
                      }}
                    >
                      <img
                        src="/android_logo.svg"
                        alt="Android Logo"
                        style={{
                          height: 24,
                          width: 24,
                          display: 'block',
                          marginRight: '1em',
                          padding: 0,
                        }}
                      />
                      <Typography level="h3" id="testsound-modal-title">
                        {t('android')}
                      </Typography>
                    </Box>
                    <Typography sx={{ textAlign: 'left', mb: 1 }}>
                      {testAndroidSoundExplanation.map((line: string, index: number) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </TabPanel>
                </Tabs>

                {/* Apple Part */}
                {/* <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                  }}
                >
                  <img
                    src="/apple_logo.png"
                    alt="Apple Logo"
                    style={{
                      height: 24,
                      width: 24,
                      display: 'block',
                      marginRight: '1em',
                      padding: 0,
                    }}
                  />
                  <Typography level="h3" id="testsound-modal-title">
                    {t('appleSubtitleTestSound')}
                  </Typography>
                </Box>
                <Typography sx={{ textAlign: 'left' }}>
                  {testAppleSoundExplanation.map((line: string, index: number) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography> */}

                {/* Android Part */}
                {/* <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    mt: 1,
                  }}
                >
                  <img
                    src="/android_logo.svg"
                    alt="Android Logo"
                    style={{
                      height: 24,
                      width: 24,
                      display: 'block',
                      marginRight: '1em',
                      padding: 0,
                    }}
                  />
                  <Typography level="h3" id="testsound-modal-title">
                    {t('androidSubtitleTestSound')}
                  </Typography>
                </Box>
                <Typography sx={{ textAlign: 'left', mb: 1 }}>
                  {testAndroidSoundExplanation.map((line: string, index: number) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography> */}

                {/* Button for replaying sound or closing modal */}
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    variant="plain" // outlined
                    color="neutral"
                    onClick={close}
                    // sx={{ alignSelf: 'stretch', mb: '1.25em' }}
                  >
                    {t('close')}
                  </Button>
                </Stack>
              </Box>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default TestSoundModalComponent;
