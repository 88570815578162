// src/views/adminevents/hooks/useEventSharing.ts

import { useState } from 'react';
import { EventInfo } from '../../../types/eventTypes';

interface UseEventSharingReturn {
  isShareModalOpen: boolean;
  eventToShare: EventInfo | null;
  openShareModal: (event: EventInfo) => void;
  closeShareModal: () => void;
}

export default function useEventSharing(): UseEventSharingReturn {
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [eventToShare, setEventToShare] = useState<EventInfo | null>(null);

  const openShareModal = (event: EventInfo) => {
    setEventToShare(event);
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
    setEventToShare(null);
  };

  return {
    isShareModalOpen,
    eventToShare,
    openShareModal,
    closeShareModal,
  };
}
