import { useRef, useState } from 'react';

interface WakeLockSentinel {
  release: () => Promise<void>;
  // You can add other properties if needed
}

interface UseWakeLockReturn {
  wakeLockActive: boolean;
  enableWakeLock: () => Promise<void>;
  disableWakeLock: () => Promise<void>;
}

const useWakeLock = (): UseWakeLockReturn => {
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);
  const [wakeLockActive, setWakeLockActive] = useState<boolean>(false);

  const enableWakeLock = async (): Promise<void> => {
    try {
      if ('wakeLock' in navigator) {
        wakeLockRef.current = await (navigator as any).wakeLock.request(
          'screen'
        );
        setWakeLockActive(true);
      } else {
        console.warn('Wake Lock API is not supported in this browser.');
      }
    } catch (err: any) {
      console.error(`Wake Lock failed: ${err.name}, ${err.message}`);
      setWakeLockActive(false);
    }
  };

  const disableWakeLock = async (): Promise<void> => {
    if (wakeLockRef.current !== null) {
      await wakeLockRef.current.release();
      wakeLockRef.current = null;
      setWakeLockActive(false);
    }
  };

  return { wakeLockActive, enableWakeLock, disableWakeLock };
};

export default useWakeLock;
