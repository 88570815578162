function cleanFilename(file: File | null): File | null {
  if (file === null) {
    return null;
  } else {
    const cleanFileName = file.name.replace(/[^\w.]+/g, '_');
    const newFile = new File([file], cleanFileName, {
      type: file.type,
      lastModified: file.lastModified,
    });
    return newFile;
  }
}

export { cleanFilename };
