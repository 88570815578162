import { useState, useEffect, useContext } from 'react';
import { API } from '../../../utils/API';
// import { Socket } from 'socket.io-client';

import { EventInfo } from '../../../types/eventTypes';
import { AuthContext } from '../../../contexts/AuthContext';
import { WebSocketContext } from '../../../contexts/WebSocketContext';

export interface UseEventListReturn {
  events: EventInfo[];
  loading: boolean;
  error: any;
}

export default function useEventList(): UseEventListReturn {
  const { userRole, userBusinessId } = useContext(AuthContext);

  const [events, setEvents] = useState<EventInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const { socket } = useContext(WebSocketContext);

  // console.log(`userRole is ${userRole} \n userBusinessId is : ${userBusinessId}`)
  const fetchAllEvents = async () => {
    // console.log(`Fetching all events \n
    // userRole : ${userRole}
    // userBusinessId : ${userBusinessId}
    // `);
    try {
      // console.log('Fetching all events!');
      let eventsResponse; // Get BusinessId from user authenticated
      if (userRole === 'super-admin') {
        eventsResponse = await API.get<EventInfo[]>(`/api/v1/events/`, {
          withCredentials: true,
        });
        // console.log(eventsResponse);
      } else {
        console.log(userBusinessId);
        eventsResponse = await API.get<EventInfo[]>(
          `/api/v1/events/listadmin/${userBusinessId}`,
          { withCredentials: true }
        );
        // console.log(eventsResponse);
      }
      setEvents(eventsResponse.data);
    } catch (error: any) {
      console.error('Failed to fetch all events:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Get Event Information
  useEffect(() => {
    fetchAllEvents();
  }, [userRole, userBusinessId]);

  // Websocket handling modification to the list of events
  useEffect(() => {
    if (!socket) {
      // console.log('No Socket found :(!');
      return;
    }

    const handleEventCreated = (newEvent: EventInfo) => {
      if (userRole !== 'super-admin' && newEvent.businessId !== userBusinessId) {
        return;
      }
      setEvents(currentEvents => [...currentEvents, newEvent]);
    };

    const handleEventUpdated = (updatedEvent: EventInfo) => {
      if (userRole !== 'super-admin' && updatedEvent.businessId !== userBusinessId) {
        return;
      }
      setEvents(currentEvents =>
        currentEvents.map(event => (event.id === updatedEvent.id ? updatedEvent : event))
      );
    };

    const handleEventDeleted = (deletedEvent: { id: string }) => {
      //   console.log(`Deleting event : ${deletedEvent.id} !`);
      setEvents(currentEvents =>
        currentEvents.filter(event => event.id !== deletedEvent.id)
      );
    };

    // console.log('Socket ID:', socket.id);

    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    socket.on('connect_error', err => {
      console.error('Socket connection error:', err);
    });

    socket.on('event-created', handleEventCreated);
    socket.on('event-updated', handleEventUpdated);
    socket.on('event-deleted', handleEventDeleted);

    return () => {
      socket.off('event-created', handleEventCreated);
      socket.off('event-updated', handleEventUpdated);
      socket.off('event-deleted', handleEventDeleted);
    };
  }, [socket]);

  return { events, loading, error };
}
