import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import TextField from '@mui/material/TextField';
// import Stack from '@mui/material/Stack';

import { Input, Stack } from '@mui/joy';

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextFieldProps } from '@mui/material';

interface FeedbackProps {
  onTimeSet: (date: Date) => void;
}

const TimePicker: React.FC<FeedbackProps> = ({ onTimeSet }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<Date | null>(new Date()); // Added null as a potential type for better handling

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
    if (newValue) {
      onTimeSet(newValue);
    }
    // console.log('Date & Hour picked:' + newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        {/* <DateTimePicker
          slots={{
            textField: (textFieldProps: TextFieldProps) => (
              <TextField {...textFieldProps} />
            ),
          }}
          // label="Schedule Time"
          value={value}
          ampm={false}
          onChange={handleChange}
          minDate={new Date()}
        /> */}
        <DateTimePicker
          onChange={newValue => {
            handleChange(newValue);
          }}
          label=""
          value={value}
          ampm={false}
          minDate={new Date()}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default TimePicker;
