import React from 'react';
import { Box, Typography, Button } from '@mui/joy';
import { EventInfo } from '../types/eventTypes';
import ApplicationMenuComponent from './ApplicationMenuComponent';
import QRCodeModalComponent from './QRCodeModalComponent';
import { useTranslation } from 'react-i18next';

interface EventInfoComponentProps {
  eventInfo: EventInfo;
  displayImage?: boolean;
  displayDescription?: boolean;
  displayScheduledTime?: boolean;
  testSound: () => void;
  shareModalOpen: boolean;
  setShareModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mode: 'light' | 'dark';
}

const EventInfoComponent: React.FC<EventInfoComponentProps> = ({
  eventInfo,
  displayImage = false,
  displayDescription = false,
  testSound,
  shareModalOpen,
  setShareModalOpen,
  mode,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Display Image + ApplicationMenu */}
      {displayImage && eventInfo.imgPath ? (
        <Box sx={{ position: 'relative', width: '100%' }}>
          <Box
            component="img"
            src={eventInfo.imgPath}
            alt={`Image representing ${eventInfo.name}`}
            sx={{
              width: '100%',
              maxHeight: '20vh',
              minHeight: '80px',
              display: 'block',
              objectFit: 'cover',
              border: 1,
              borderColor: 'divider',
            }}
          />
          {!eventInfo.isPassed && (
            <Box
              sx={{
                p: 2,
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setShareModalOpen(true)}
                className="action-icons"
                sx={{
                  mx: 1,
                  backgroundColor:
                    mode === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.7)',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <span className="material-symbols-outlined">share</span>
                {t('share')}
              </Button>
              <ApplicationMenuComponent testSound={testSound} />
            </Box>
          )}
        </Box>
      ) : (
        // Display ApplicationMenu
        <Box
          sx={{
            px: 2,
            pt: 1,
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setShareModalOpen(true)}
            className="action-icons"
            sx={{
              mx: 1,
              backgroundColor:
                mode === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.7)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <span className="material-symbols-outlined">share</span>
            {t('share')}
          </Button>
          <ApplicationMenuComponent testSound={testSound} />
        </Box>
      )}

      {/* Event Name & Description  */}
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          px: 2,
          pb: 1,
          mt: 1,
        }}
      >
        <Box
          sx={{
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          <Typography level="h1">{eventInfo.name}</Typography>
          {eventInfo.location && eventInfo.location !== eventInfo.name && (
            <Typography level="title-sm">{eventInfo.location}</Typography>
          )}

          {displayDescription && eventInfo.description! && (
            <Typography sx={{ whiteSpace: 'pre-line', pb: 2 }}>
              {eventInfo.description}
            </Typography>
          )}
        </Box>
      </Box>
      {eventInfo && (
        <QRCodeModalComponent
          open={shareModalOpen}
          handleClose={() => setShareModalOpen(false)}
          eventData={eventInfo}
          isPyroPage={false}
          size={256}
        />
      )}
    </>
  );
};

export default EventInfoComponent;
