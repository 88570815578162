function formatDate(dateString: string): string {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateWHours(dateString: string): string {
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  if (!dateString) {
    console.error('Date is null or undefined');
    return 'Invalid Date';
  }

  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString(undefined, optionsDate);
  const formattedTime = date.toLocaleTimeString(undefined, optionsTime);

  return `${formattedDate} - ${formattedTime}`;
}

function formatHour(dateString: string): string {
  const date = new Date(dateString);
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

function formatSeconds(
  seconds: number,
  showSeconds: boolean = true,
  showMinutes: boolean = true
): string {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  let result = '';

  if (days > 0) result += `${days} jours `;
  if (hours > 0) result += `${hours}h `;
  if (showMinutes && minutes > 0) result += `${minutes}min `;
  if (showSeconds && (remainingSeconds > 0 || result === ''))
    // Show Seconds optional
    result += `${remainingSeconds}s`;

  return result.trim();
}

const formatLabel = (key: string) => {
  // Convert camelCase or snake_case to words
  const words = key
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .toLowerCase();
  return words.charAt(0).toUpperCase() + words.slice(1);
};

export { formatDate, formatDateWHours, formatHour, formatSeconds, formatLabel };
