import React from 'react';
import {
  Table,
  Button,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Chip,
  Typography,
  Box,
} from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { formatDateWHours } from '../../../utils/formatUtils';

// import { Link } from 'react-router-dom';
import { useTheme } from '@mui/joy/styles';

import { EventInfo } from '../../../types/eventTypes';

interface EventsTableProps {
  events: EventInfo[];
  onEditEvent: (id: string) => void;
  onDeleteEvent: (id: string) => void;
  onShareEvent: (id: string) => void;
  onPayEvent: (id: string) => void;
}

const EventsTable: React.FC<EventsTableProps> = ({
  events,
  onEditEvent,
  onDeleteEvent,
  onShareEvent,
  onPayEvent,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  function getStatusProps(status: string): {
    color: 'danger' | 'success' | 'primary' | 'neutral' | 'warning' | undefined;
    variant: 'soft' | 'outlined' | 'plain' | 'solid' | undefined;
  } {
    switch (status) {
      case t('eventUnpaidStatus'):
        return { color: 'danger' as const, variant: 'soft' as const };
      case 'paid':
        return { color: 'success' as const, variant: 'soft' as const };
      case t('eventPassedStatus'):
        return { color: 'primary' as const, variant: 'soft' as const };
      case 'Unpaid':
        return { color: 'danger' as const, variant: 'soft' as const };
      default:
        return { color: 'primary' as const, variant: 'outlined' as const };
    }
  }

  return (
    <Table aria-label="events table" variant="outlined" color="primary" hoverRow>
      <thead>
        <tr>
          <th align="left">{t('city')}</th>
          <th align="left">{t('name')}</th>
          {/* <th align="left">{t('description')}</th> */}
          <th align="left" style={{ width: '140px' }}>
            {t('date')}
          </th>
          <th align="left">{t('files')}</th>
          <th align="left" style={{ width: '80px' }}>
            {t('status')}
          </th>
          <th align="left" style={{ width: '180px' }}>
            {t('actions')}
          </th>
        </tr>
      </thead>
      <Box component="tbody" sx={{ borderRadius: '2em' }}>
        {events.map(event => (
          <tr
            key={event.id || ''}
            style={{ color: event.isPassed ? theme.palette.neutral[400] : 'inherit' }}
          >
            <td align="left">{event.location}</td>
            <td align="left">{event.name}</td>
            <td align="left">
              {event.scheduledTime ? formatDateWHours(event.scheduledTime) : 'N/A'}
            </td>
            <td align="left">
              {' '}
              {`${event.originalEventImageName} \n ${event.originalMusicName}`}
            </td>
            <td align="left" style={{ width: '80px' }}>
              <Chip {...getStatusProps(event.status || '')}>{event.status}</Chip>
            </td>
            {event.id && (
              <td align="left" style={{ textAlign: 'center', width: '20px' }}>
                <Button
                  variant="outlined"
                  onClick={() => onShareEvent(event.id!)}
                  className="action-icons"
                  sx={{ px: 1, py: 0.5 }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: '1.2rem' }}
                  >
                    share
                  </span>
                  <Typography level="body-xs" sx={{ fontSize: '1rem' }}>
                    {t('share')}
                  </Typography>
                </Button>
                <>
                  <Dropdown>
                    <MenuButton variant="plain">
                      <span className="material-symbols-outlined">more_vert</span>
                    </MenuButton>
                    <Menu placement="top-end" sx={{ zIndex: 30 }}>
                      <MenuItem
                        color="success"
                        disabled
                        onClick={() => onPayEvent(event.id!)}
                        className="action-icons"
                      >
                        <span className="material-symbols-outlined">payments</span>
                        <Typography level="title-sm">{t('pay')}</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => onEditEvent(event.id!)}
                        className="action-icons"
                      >
                        <span className="material-symbols-outlined">edit</span>
                        <Typography level="title-sm">{t('edit')}</Typography>
                      </MenuItem>
                      <MenuItem
                        color="danger"
                        onClick={() => onDeleteEvent(event.id!)}
                        className="action-icons"
                      >
                        <span className="material-symbols-outlined">delete</span>
                        <Typography level="title-sm">{t('delete')}</Typography>
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </>
              </td>
            )}
          </tr>
        ))}
      </Box>
    </Table>
  );
};

export default EventsTable;
